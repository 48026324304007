.rowdy-header-wrapper{
    width: 100%;
    position: relative;
}

.rowdy-header-wrapper .containerFull{
    display: flex;
    justify-content: space-between;
    padding: 5% 0;
}
.orange-bg{
    background-color: var(--orange-color);
    margin-top: 7.55%;
    width: 100%;
    height: 45%;
    position: absolute;
    left: 0;
    z-index: -1;

}
.rowdy-header__title{
    width: 40%;
    margin-top: 5%;
    margin-bottom: 1rem;
}

.rowdy-header__title h1{
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--white-color);
    margin-bottom: 2rem;
    text-shadow: -7px 5px 4px rgba(0, 0, 0, 0.25); 
}
.rowdy-header__title h2{
    font-size: 2rem;
    font-weight: 500;
    font-style: italic;
    color: var(--white-color);
}
.rowdy-header__title p{
    font-weight: 400;
    color: var(--black-gray);
    margin-top: 50%;
    line-height: 2;
}

.rowdy-header__img{
    width: 50%;
    position: relative;
}
.rowdy-header__img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* CHALLENGE */
.rowdy-challenge-wrapper{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    color: var(--white-color);
    position: relative;
}
.red-circle{
    width: 100%;
    position: absolute;
    z-index: -1;
}
.red-circle img{
    width: 100%;
    object-fit: cover;
    object-position: top;
    top: 0;
}
.rowdy-challenge__text{
    width: 40%;
    margin: auto;
    padding: 12% 0 2%;
    position: relative;
    z-index: 1;
}
.rowdy-challenge__text h2{
    font-size: 2.5rem;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 1rem;
}
.rowdy-challenge__text span{
    opacity: .7;
    font-size: 2.2rem;
    font-weight: 700;
}
.rowdy-challenge__text p{
    line-height: 2;
    text-align: justify;
    margin-top: 2rem;
}
.rowdy-challenge__img{
    width: 100%;
}
.rowdy-challenge__img img{
    width: 100%;
    height: 100%;
}


/* SOLUTION */
.rowdy-solution-wrapper{
    width: 100%;
    background-color: var(--wine-purple);
    color: var(--white-color);
    position: relative;
    margin-top: -1rem;
}
.rowdy-solution-wrapper .containerFull{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rowdy-solution__text{
    width: 40%;
}
.rowdy-solution__text h2{
    font-size: 2.5rem;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 1rem;
}
.rowdy-solution__text span{
    opacity: .7;
    font-size: 2.2rem;
    font-weight: 700;
}
.rowdy-solution__text p{
    line-height: 2;
    text-align: justify;
    margin-top: 2rem;
}

.rowdy-solution__img{
    width: 60%;
    position: relative;
    right: -10%;
}
.rowdy-solution__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* FEATURES */
.rowdy-features-wrapper{
    width: 100%;
    color: var(--black-gray);
    position: relative;
    padding: 5% 0;
}
.rowdy-features-wrapper .containerFull{
    display: flex;
    justify-content: space-between;
}
.rowdy-features__img{ 
    width: 60%;
    position: relative;
    left: -5%;
}
.rowdy-features__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.rowdy-features__text{
    width: 35%;
}
.rowdy-features__text h2{
    font-size: 2.5rem;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 1rem;
} 
.rowdy-features__text ul{
    margin-top: 2rem;
    width: 80%;
}
.rowdy-features__text li{
    font-weight: 400;
    line-height: 2;
    margin-top: 3rem;
    list-style-type: disc;
    list-style-position: inside;
    font-style: italic;
}
.rowdy-features__text span{
    font-weight: 300;
    font-style: normal;
}

/* PROCESS */
.rowdy-process-wrapper{
    width: 100%;
    background-color: var(--pine-purple);
    color: var(--white-color);
    overflow: hidden;
}
.fruits-bg{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
}
.fruits-bg img{
    width: 100%;
    position: absolute;
    z-index: 1;
}
.fruits-bg .circle{
    width: 80%;
    margin-top: 10%;
}
.rodwy-process__text{
    width: 40%;
    margin: auto;
    padding: 23% 0 5%;
    position: relative;
    z-index: 3;
    text-align: center;
}
.rodwy-process__text h2{
    font-style: italic;
    margin-bottom: 2.5rem;
}
.rodwy-process__text p{
    line-height: 2;
}

/* OUTCOME */
.rowdy-outcome{
    width: 100%;
    padding: 8% 0 0;
    position: relative;
    background-color: var(--pine-purple);
    color: var(--white-color);
    box-shadow: 5px -5px 10px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    z-index: 5;
}

.rowdy-outcome .containerFull{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rodwy-outcome__img{
    height: 100%;
    position: relative;
    left: -10%;
    margin-bottom: -8%;
}
.rodwy-outcome__img img{
    width: 100%;
}
.rodwy-outcome__text{
    width: 40%;
    text-align: right;
}
.rodwy-outcome__text h2{
    font-style: italic;
    margin-bottom: 1.5rem;
}
.rodwy-outcome__text p{
    line-height: 2;
    margin-bottom: 2rem;
}


@media (max-width: 1250px ) {

    .rowdy-header__title{
        width: 50%;
    }
    .rowdy-header__title h1{
        font-size: 3.5rem;
    }
    .rowdy-header__title h2{
        font-size: 1.5rem;
    }
    .rowdy-header__title p{
        margin-top: 20%;
    }

    .rowdy-challenge__text{
        text-align: center;
        width: 80%;
        padding-bottom: 3%;
    }

    .rowdy-solution__text{
        width: 60%;
        padding: 7% 0;
    }

    .fruits-bg .circle{
        margin-top: 15%;
    }
    .rodwy-process__text{
        width: 50%;
        padding-top: 30%;
    }
    .rodwy-process__text h2{
        font-size: 2.5rem;
    }
    .rodwy-process__text p{
        margin-top: 2rem;
    }

    .rodwy-outcome__img{
        width: 50%;
    }
    .rodwy-outcome__text{
        width: 70%;
    }
}

@media (max-width: 1000px ) {

    .orange-bg{
        margin-top: 0;
    }
    .rowdy-header-wrapper .containerFull{
        flex-direction: column;
    }
    .rowdy-header__title{
        width: 100%;
        padding-top: 5%
    }
    .rowdy-header__title p{
        margin-top: 5%;
        color: var(--white-color);
    }
    .rowdy-header__img{
        width: 100%;
        margin-top: 5%;
    }

    .rowdy-challenge__text{
        width: 100%;
        padding: 8% 0 4%;
        background-color: var(--vivid-red);
    }
    .rowdy-challenge__text p{
        width: 80%;
        margin: 2rem auto;
    }
    .rowdy-challenge__img{
        margin-top: -1rem;
    }
    .rowdy-solution-wrapper .containerFull{
        flex-direction: column;
    }
    .rowdy-solution__text{
        width: 100%;
        text-align: center;
    }
    .rowdy-solution__img{
        width: 100%;
        right: 0;
    }

    .rowdy-features-wrapper{
        padding: 10% 0;
    }
    .rowdy-features-wrapper .containerFull{
        flex-direction: column-reverse;
    }
    .rowdy-features__text{
        width: 100%;
    }
    .rowdy-features__text h2{
        text-align: center;
    }
    .rowdy-features__text ul{
        width: 100%;
    }
    .rowdy-features__img{
        width: 100%;
        margin-top: 5%;
        left: 0;
    }
    .fruits-bg .circle{
        width: 90%;
        margin-top: 8%;
    }
    .rodwy-process__text{
        width: 70%;
        padding-top: 25%;
    }

    .rodwy-outcome__img{
        display: none;
    }
    .rodwy-outcome__text{
        width: 100%;
    }
}


@media (max-width: 750px ) {

    .orange-bg{
        height: 80%;
    }
    .rowdy-header__title{
        text-align: center;
    }
    .rowdy-header__title h1{
        font-size: 3rem;
    }
}