/******** STAFFWIZARD HEADER ********/

.staffwizard-header{
    height: auto;
    background: var(--night-blue);
    position: relative;
    color: var(--white-color);
}

.staffwizard-header .containerFull{
    height: 100%;
    position: relative;
}

.staff-header-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 8rem 0;
}

.gradient{
    position: absolute;
    top: -17%;
    left: -35%;
}

.staff-header-title{
    position: relative;
    margin-top: 2rem;
    width: 60%;
    z-index: 5;
}

.staff-header-title h1{
    font-size: 6.3rem;
    font-weight: 700;
    line-height: 1.2;
}

.staff-header-title p{
    font-size: 4rem;
    font-weight: 100;
    font-style: italic;
    line-height: 1;
    margin-bottom: 2rem;
}

.header-img{
    position: absolute;
    top: 15.23%;
    left: 45%;
}




/* HEADERTEXT */
.header-text, .approach-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--bone-white);
}

.header-content p{
    width: 90%;
    margin: 5% 0 10%;
    line-height: 2.2rem;
}

/* APPROACH */

.approach-content{
    width: 100%;
    max-width: 1700px;
    display: flex;
    align-items: center;
}

.approach-img{
    height: 100%;
    width: 50%;
    
}

.approach-img img{
    height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.approach-text{
    width: 45%;
    margin-left: 2rem;
}

.approach-title{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.approach-title h2{
    flex-shrink: 0;
    font-size: 2rem;
    font-weight: 400;
    margin-right: 1rem;
}
.title-img{
    display: flex;
    align-items: center;
}
.approach-title img{
    width: 100%;
}

.approach-text{
    width: 47%;
    margin-left: 2rem;
}

.approach-text p{
    width: 80%;
    line-height: 2.2rem;
}

/* INNOVATION */

.innovation-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--formal-blue);
}

.innovation-text{
    width: 48%;
    margin-right: 2rem;
    text-align: right;
}

.innovation-title{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.innovation-title h2{
    flex-shrink: 0;
    font-size: 2rem;
    font-weight: 400;
    margin-left: 1rem;
}
.innovation-title img{
    width: 100%;
}

.innovation-text p{
    width: 80%;
    line-height: 2.2rem;
    float: right;
}

/* IMPROVEMENT */

.improvement{
    height: auto;
    background: var(--blue-black);
    position: relative;
    color: var(--white-color);
}

.improvement-wrapper .containerFull{
    height: 100%;
    position: relative;
}

.improvement-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 8rem 0;
    text-align: center;
}

.improvement-title h2{
    width: 40%;
    margin: auto;
    font-size: 2.5rem;
    font-weight: 100;
    font-style: italic;
}

.improvement-title span{
    font-style: normal;
    font-weight: 500;
}

.improvement-points{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5rem;
    position: relative;
}

.point{
    width: 18%;
    margin: 0 2rem;
    font-weight: 100;
}

.point-number{
    font-size: 4rem;
    margin-bottom: 2rem;
    font-style: italic;
}

.point-text h4{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.lines{
    width: 60%;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    top: 2.5rem;
}

.improvement-points hr{
    width: 25%;
    height: .5px;
    background-color: var(--white-color);
    border: none;
}


/* SOLUTIONS */
.solutions{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--beige-yellow);
}

.solutions-content{
    width: 100%;
    margin: 5% 0 0;
    float: right;
}

.solutions .improvement-title{
    width: 90%;
    float: right;
}
.solutions .improvement-title h2{
    width: 100%;
    margin-bottom: 2rem;
    text-align: right;
}

.solutions p{
    width: 89%;
    line-height: 2.2rem;
    float: right;
}

.solutions-img{
    width: 100%;
}

.solutions-img img{
    width: 100%;
    height: 100%;
    margin-bottom: -.4rem;
}


@media screen and (max-width: 1250px){
    
    .approach-text, .innovation-text{
        padding: 2rem 0;
    }

    .improvement-title h2{
        width: 100%;
    }

    .lines{
        display: none;
    }

    .improvement-points{
        flex-wrap: wrap;
    }

    .point{
        width: 40%;
        margin: 0 0 2rem;
    }

}


@media (max-width: 1000px ) {
    
    .staff-header-wrapper{
        padding: 4rem 0 2rem;
    }
    .staff-header-title{
        width: 100%;
    }
    .header-img{
        position: relative;
        width: 100%;
        margin: auto;
        top: 0;
        left: 0;
    }

    .header-img img{
        width: 100%;
    }

    .header-content p{
        width: 100%;
        text-align: center;
        margin-top: 10%;
    }

    .innovation-title h2{
        flex-shrink: 1;
    }

    .innovation-text{
        width: 45.5%;
    }

    .point{
        width: 80%;
        margin-bottom: 5rem;
    }

    .solutions .improvement-title {
        width: 100%;
        text-align: center;
    }

    .solutions .improvement-title h2{
        text-align: center;
    }

    .solutions p{
        width: 100%;
    }

    
}


@media (max-width: 700px){

    .gradient{

    }

    .staff-header-title h1 {
        font-size: 3.5rem;
    }

    .staff-header-title p{
        font-size: 2.2rem;
    }
    
    .approach-content{
        flex-direction: column-reverse;
        width: 90%;
    }

    .approach-img{
        width: 100%;
        margin-bottom: 2rem;
    }

    .approach-text{
        width: 100%;
        margin-left: 0;
    }
    .approach-text p, .innovation-text p{
        width: 100%;
    }
    .innovation-wrapper .approach-img{
        width: 100%;
        margin-bottom: 0;
        margin-top: 2rem;
    }

    .innovation-text{
        width: 100%;
        margin-left: 0;
    }



}


