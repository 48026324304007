/******** platform HEADER ********/

.platform-header{
    height: 46rem;
    background: var(--bone-white);
    position: relative;
}

.platform-header .containerFull{
    height: 100%;
}

.platform-header-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}


.platform-header-copy{
    width: 90%;
    max-width: 50rem;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    color: var(--dark-blue);
    text-align: center;
    row-gap: 2rem;
    justify-content: center;
}
.platform-header-copy p{
    line-height: 2;
}

.platform-header-icon {
    width: 5rem;
    height: 5rem;
    background-color: var(--white-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-shadow: 15px 15px 40px rgb(0 0 0 / 20%);
    margin-top: -10rem;
}

.platform-header-icon svg{
    width: 2.4rem;
}

.platform-header-icon svg path{
    fill: var(--dark-blue);
}

/******** TECHNOLOGIES ********/

.technology-platform{
    position: relative;
    background-color: var(--dark-blue);
    overflow: hidden;
    padding: 5rem 0;
}

.technology-platform-wrapper{
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 58.75rem;
    text-align: center;
    color: var(--white-color);
}

.technology-platform-wrapper h3 {
    margin-bottom: 2.75rem;
}

.technology-platform-wrapper p {
    line-height: 2;
}

.technology-platform-logo-list {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.technology-platform .tech-logo-item{
    width: 5.625rem;
    height: 5.625rem;
    background-color: var(--white-color);
}

.technology-platform .tech-logo-item img{
    max-height: 80%;
}

@media (max-width: 1200px) {

}

@media (max-width: 900px) {
    
    .technology-platform-logo-list {
        justify-content: center;
        gap: 1rem;
    }
}



