body{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

h1, h2, h3, h4, h5, h6, p, a{
    margin: 0;
    text-decoration: none;
}
ul, li{
    margin: 0; 
    padding: 0;
    list-style: none;
}
button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
h2{
    font-weight: bold;
    font-size: 3rem;
    line-height: 1.25;
}
h3{
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 1.2;
}
h5{
    font-weight:600;
    font-size: 1.25rem;
    line-height: 1.25;
}
h6{
    font-weight:600;
    font-size: 1.125rem;
    line-height: 1.25;
}
p{
    line-height: 1.5;
}

.bgr-noise{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../images/noise.png);
    opacity: 50%;
    z-index: 1;
}

.bgr-dots{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../images/dots-bgr.png);
    opacity: 50%;
    z-index: -1;
}


header {
    width: 100%;
    height: var(--navbar-heigh);
    display: flex;
    justify-content: center;
    background-color: var(--white-color);
    position: fixed;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    z-index: 999;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-wrapper{
    width: 100%;
    overflow: hidden;
    padding-top: var(--navbar-heigh)
}

.center{ text-align: center;}

.baseButtonCentered{
    margin: 0 auto;
    box-shadow: 10px 10px 20px 0px rgb(16 24 40 / 15%);
}

.baseButton{
    border-radius: 8px;
    display: flex;
    line-height: 1;
    font-weight: 600;
    border-radius: 16px;
    padding: 1rem 2.25rem;
} 

.thinButton{
    padding: 0.5rem 1.18rem;
    line-height: 1;
    display: flex;
    column-gap: 0.35rem;
    box-shadow: 10px 10px 20px 0px rgb(16 24 40 / 15%);
    border-radius: 3rem;
    font-weight: 600;
    cursor: pointer;
}
.thinButton span{
    position: relative;
    z-index: 5;
}
.thinButton svg{
    width: 0.9rem;
}

.see-more-btn{
    background-color: var(--white-color);
    padding: 0.5rem 1.18rem;
    line-height: 1;
    display: flex;
    column-gap: 0.35rem;
    box-shadow: 10px 10px 20px 0px rgb(16 24 40 / 15%);
    border-radius: 3rem;
    cursor: pointer;
}
.see-more-btn span{
    font-weight: 500;
    color: transparent;
    display: inline-block;
    font-size: 0.87rem;
    line-height: 1;
}
.see-more-btn svg{
    width: 0.9rem;
}

.absolute-right-bottom{
    position: absolute;
    right: 2.5rem;
    bottom: 2rem;
}

.title-underline{
    height: 0.45rem;
    max-width: 45rem;
    width: 70%;
    margin: 2rem auto;
    border-radius: 5rem;
}
.title-underline.blue-green-orange{
    background: var(--gradient-blue-green-orange);
}
.title-underline.orange-violet-pink{
    background: var(--gradient-orange-violet-pink);
}
.title-underline.blue-violet-pink{
    background: var(--gradient-blue-violet-pink);
}

.containerFull{
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
}

ul.navBarList{
    padding: 0;
    display: flex;
    column-gap: 2rem;
}

ul.navBarList > li{
    position: relative;
    cursor: pointer;
    min-height: 3rem;
    display: flex;
    align-items: center;
}

ul.navBarList > li > a, ul.navBarList > li > span{
    color: var(--midnight-blue);
    font-weight: 600;
}

@media (hover ) {

    ul.navBarList > li > a:hover,
    ul.navBarList li.active li.active,  
    ul.navBarList li.active > a, 
    ul.navBarList > li:hover > span,
    ul.navBarList > li.active span{
        color: var(--orchid-pink);
    }

    .navBarList-child li:hover,
    .navBarList-child li:hover a{
        color: var(--orchid-pink);
    }

    ul.navBarList > li:hover .navBarList-child{
        height: auto;
        max-height: 500px;
        transform: scaleY(1);
        opacity: 1;
        transition:  max-height 150ms ease-in, opacity 250ms ease-in, transform 50ms ease-in ;
        transform-origin: top center;
    }
}


    

.navBarList-child{
    position: absolute;
    left: -20rem;
    top: 3rem;
    box-sizing: border-box;
    background-color: rgb(255 255 255 / 70%);
    width: 44rem;
    padding: 1.5rem 4.6rem 2.5rem 4.6rem;
    backdrop-filter: blur(20px);
    box-shadow: -5px 15px 30px rgb(0 0 0 / 10%);
    height: 0;
    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    opacity: 0;
    transition:  max-height 150ms ease-out, opacity 250ms ease-out, transform 50ms ease-out;
    transform-origin: top center;
}

.navBarList-child li{
    margin-bottom: 1rem;
    list-style: disc;
}
.navBarList-child li:last-child{
    margin-bottom: 0;
}
.navBarList-child a{
    color: var(--midnight-blue);
    font-size: 0.875rem;
}
.submenu{
    height: 0;
    opacity: 0;
    width: 0;
}


.menu-bar-wrapper{
    display: none;
    width: 1.75rem;
}

.menu-bar-wrapper img{
    width: 100%;
    max-height: 1.375rem;
}

.navBarLogo{
    width: 8rem;
    display: block;
}

.navBarLogo img{
    width: 100%;
    display: block;
}

.slide-in{
    position: fixed;
    top: var(--navbar-heigh);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--navbar-heigh) );
    background: var(--royal-purple);
    transform-origin: bottom;
    z-index: 99;
}

.slide-out{
    position: fixed;
    top: var(--navbar-heigh);
    left: 0;
    width: 100%;
    height: calc( 100vh - var(--navbar-heigh) );
    background: var(--royal-purple);
    transform-origin: top;
    z-index: 99;
}

/*********** Hero ************/

.heroWrapper{
    display: flex;
    justify-content: center;
}

.heroWrapper .containerFull{
    position: relative;
}

.sliderWrapper{
    width: 100%;
    padding: 4rem 0;
    position: relative;
    z-index: 2;
}
.heroSlide{
    width: 100%;
}

.bgCircle{
    width: 32.5rem;
    height: 32.5rem;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    position: absolute;
    border-radius: 50%;
    filter: blur(250px);
    z-index: -1;
    opacity: 40%;
}

.bgCircle-pink{
    background-color: var(--orchid-pink);
}
.bgCircle-orange{
    background-color: var(--tangerine-orange);

}
.bgCircle-red{
    background-color: var(--crimson-red);
}
.bgCircle-blue{
    background-color: var(--azure-blue);
}
.bgCircle-violet{
    background-color: var(--vivid-purple);
}
.bgCircle-yellow{
    background-color: var(--sunset-gold);
}
.bgCircle-green{
    background-color: var(--green-mint);
}

.bgBlur-right-top{
    right: 10%;
    top: -10%;
}
.bgBlur-left-top{
    left: -10%;
    top: -10%;
}

.bgBlur-right-bottom{
    right: 40%;
    bottom: -20%;
}

.heroSlide-1{
    display: flex;
}

.heroTextWrapper{
    width: 56%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    row-gap: 1.5rem;
}
.heroTextWrapper h2{
    line-height: 1.25;
    color: var(--midnight-blue);
}

.heroImageWrapper{
    width: 44%;
}
.heroImage img{
    width: 100%;
}

/*********** ServicesRoot ************/

.servicesRootWrapper{
    padding: 5rem 0;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.ServicesRoot{
    background-color: var(--royal-purple);
}

.ServicesRoot h3{
    color: var(--white-color);
}

.servicesRootWrapper .swiper-wrapper{
    margin-top: 2rem;
}

.servicesRootWrapper .swiper-wrapper .swiper-slide{
    background-color: var(--white-color);
    border-radius: 10px;
    width: 21rem;
    height: 23rem;
    transition: width ease-in-out .450s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    /*padding: 2rem 2.52rem;*/
}


.service-card-wrapper{
    width: 60rem;
    display: flex;
    height: 100%;
}

.service-card-wrapper .service-card-overview{
    box-sizing: border-box;
    width: 21rem;
    padding: 2rem 2.5rem;
}
.service-card-wrapper .service-card-capabilities{
    box-sizing: border-box;
    width: 39rem;
    padding: 2rem 2.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.service-card-capabilities .see-more-btn span{
    background: var(--gradient-purple-pink-orange);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.servicesRootWrapper .swiper-wrapper .swiper-slide .service-icon{
    width: 5rem;
    height: 5rem;
    margin-bottom: 2.5rem;
    border-radius: 50%;
    background-color: var(--light-gray);
    display: flex;
    justify-content: center;
}

.servicesRootWrapper .swiper-wrapper .swiper-slide .service-icon svg{
    width: 52%;
    fill: var(--pale-blue);
}

.servicesRootWrapper .swiper-wrapper .swiper-slide h5{
    color: var(--vivid-purple);
    margin-bottom: 1rem;
}

.servicesRootWrapper .swiper-wrapper .swiper-slide p{
    font-size: .875rem;
    color: var(--slate-gray);
    line-height: 1.75;
}

.servicesRootWrapper .swiper-slide.swiper-slide-active{
    width: 60rem;
    transition: width ease-in .25s;
}

.servicesRootWrapper .swiper-slide.swiper-slide-active .service-card-overview h5{
    background: var(--gradient-purple-pink-orange);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    display: inline-block;
}

.servicesRootWrapper .swiper-slide.swiper-slide-active .service-icon svg{
    fill: var(--vivid-purple); 
}

.service-card-wrapper .capabilities-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
}

.service-card-wrapper .capabilities-grid-item{
    text-align: center;
    font-size: 0.75rem;
    padding: 0.5rem 0.2rem;
    background-color: var(--light-gray);
    color: var(--slate-gray);
    border-radius: 10rem;
}

.services-cards-dots{
    column-gap: 0.45rem;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    margin-top: 2rem;
}

.servicesRootWrapper .swiper-pagination-bullet {
    width: 1rem !important;
    height: 1rem !important;
    background-color: var(--heart-purple) !important;
    opacity: 1 !important;
}
.servicesRootWrapper .swiper-pagination-bullet-active{
    background-color: var(--light-purple) !important;
}

.servicesRootWrapper .swiper {
    width: 100%;
    height: 100%;
}


/*********** Our Clients ************/

.ourClientsWrapper{
    padding: 5em 0;
    text-align: center;
}

.ourClientsWrapper h3{
    color: var(--midnight-blue);
}


.clients-list-wrapper{
    width: 100%;
    height: 5.5rem;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    margin-top: 4.5rem;
}

.clients-list-track{
    display: flex;
    column-gap: 4.5rem;
    align-items: center;

    position: absolute;
    animation: marquee 32s linear infinite;
}
.clients-list-track::before,
.clients-list-track::after {
    content: "";
    display: block;
    flex: 0 0 4.5rem;
}
.clients-list-track img{
    display: block;
}

  
@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}


/*********** Testimonials ************/

.testimonialWrapper{
    padding: 5em 0;
    text-align: center;
    background-color: var(--midnight-blue);
    color: var(--white-color);
}

.testimonial-item{
    width: 100%;
    text-align: left;
}

.testimonial-item h6{
    color: var(--white-color);
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    font-weight: 400;
}
.testimonial-item h5{
    font-size: .85rem;
    color: var(--sky-blue);
}
.testimonial-item p{
    font-size: .7rem;
    line-height: 2;
}

.testimonial-bubbles-wrapper {
    /*display: flex;
    column-gap: 8rem;
    justify-content: center;*/
    max-width: 63.75rem;
    margin-top: 4rem;
    margin: 4rem auto auto auto;
}

.testimonial-bubble{
    width: 100%;
    height: 21.5rem;
    box-sizing: border-box;
    border-radius: 2rem 2rem 2rem 0rem;
    font-size: 0.85rem;
    font-weight: 200;
    background-color: var(--navy-blue);
    padding: 2rem 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ranking-star-wrapper{
    margin-top: .75rem;
    display: flex;
    column-gap: .45rem;
}

.ranking-star{
    width: 1.125rem;
}

/*********** Articles, Events and cases ************/


.articleEventCasesWrapper{
    padding: 8rem 0;
    overflow: hidden;
}

.subsection-articles {
    padding-bottom: 3rem;
    position: relative;
}

.articleEventCasesWrapper h3{
    text-align: center;
    color: var(--midnight-blue);
}

.articlesNavWrapper {
    width: 70%;
    margin: 3rem auto 3rem auto;
    display: grid;
    grid-template-columns: 5.2rem 1fr;
    align-items: center;
}
.articlesNavWrapper a{
    color: var(--slate-gray);
    font-size: 1.2rem;
    font-weight: 600;
    padding: "0.25rem 1.5rem";
    cursor: pointer;
}

.articles-grid {
    padding-bottom: 2rem;
}

.articles-grid .swiper{
    overflow: visible;
    /*overflow: clip;*/
}

.article-grid-item{
    border-radius: var(--radius20);
    position: relative;
    padding-top: 60%;
    margin-bottom: 7rem;
    cursor: pointer;
}

.article-grid-image{
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: initial;
    height: initial;
    background-color: var(--slate-gray);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--radius20);
    max-width: 100%;
    max-height: 100%;
}
.article-grid-image img{
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.article-grid-stick{
    position:absolute;
    top: 1rem;
    right: 1rem;
    font-size: 0.75rem;
    color: var(--midnight-blue);
    background-color: var(--tangerine-orange);
    padding: .2rem .7rem;
    border-radius: 10rem;
}

.article-grid-label{
    background-color: rgb(255 255 255 / 85%);
    width: calc(82.5% - 2.4rem);
    height: calc( 7rem - 2rem);
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3.5rem;
    margin: auto;
    border-radius: var(--radius20);
    padding: 1rem 1.2rem;
    font-size: 0.875rem;
    box-shadow: 10px 10px 30px 0px rgb(0 0 0 / 15%);
    backdrop-filter: blur(50px);
}

.article-grid-capabilities-group{
    display: flex;
    column-gap: .75rem;
    margin-top: .6rem;
}

.article-grid-capabilities-group .article-capability{
    background-color: var(--pale-blue);
    border-radius: 10rem;
    color: var(--white-color);
    padding: .5rem 1rem;
    font-size: 0.75rem;
    line-height: 1;
}

.subsection-events {
    padding-top: 2rem;
    position: relative;
}

.events-grid{
    display: flex;
    flex-direction: column;
    row-gap: 5.6rem;
    margin-top: 4rem;
}
.event-grid-item {
    display: grid;
    grid-template-columns: 18.8rem 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.75rem;
    grid-row-gap: 2.75rem;
}

.event-grid-image { 
    position: relative;
    border-radius: var(--radius20);
    background-color: var(--slate-gray);
    grid-area: 1 / 1 / 3 / 2; 
}

.event-grid-image-box{
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: var(--light-gray);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--radius20);
}
.event-grid-image-box img{
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.event-grid-info { 
    padding-left: 4.5rem;
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
}

.event-grid-description { 
    padding: 1.25rem 2rem;
    border-radius: var(--radius20);;
    grid-area: 2 / 2 / 3 / 3;
    box-shadow: 10px 10px 50px 0px rgb(0 0 0 / 25%);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px); 
}

.event-grid-date {
    border-radius: 5px 5px 0px 0px;
    overflow: hidden;
    width: 6.8rem;
    height: 6.8rem;
    background-color: var(--white-color);
    position: absolute;
    text-align: center;
    right: -4rem;
    top: -1rem;
    box-shadow: 10px 10px 50px 0px rgb(0 0 0 / 25%);
}
.event-grid-month{
    font-size: 1.5rem;
    background-color: var(--azure-blue);
    color: var(--white-color);
    font-weight: 500;
}
.event-grid-day{
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.76;
}

.event-grid-title p{
    font-size: 1.25rem;
}
.event-grid-hour{
    display: flex;
    column-gap: .5rem;
    align-items: center;
}
.event-grid-hour p{
    font-weight: 200;
}
.event-grid-hour img{
    width: 1.15rem;
}

.event-grid-address {
    display: flex;
    column-gap: .5rem;
    align-items: center;
}
.event-grid-address p{
    font-weight: 200;
}
.event-grid-address img{
    width: 1rem;
}

.event-grid-description{
    background-color: var(--white-color);
}

.event-grid-description p{
    font-size: 0.875rem;
}

/*********** MainCase ************/

.mainCaseWrapper{
    position: relative;
    padding: 5rem 0;
    background-color: var(--midnight-blue);
    color: var(--white-color);
    background-position: center;
    background-size: cover;
}


/*********** Newsletter ************/

.newsletterWrapper{
    padding: 5rem 0;
    background: var(--gradient-pink-violet-blue);
    color: var(--white-color);
}
.newsletterWrapper .containerFull{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.newsletterForm{
    width: 50%;
    text-align: center;
}

.newsletterForm form{
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    column-gap: 1rem;
}
.newsletterForm input{
    background-color: var(--white-color);
    border-radius: 20px;
    color: var(--midnight-blue);
    padding: 1rem 1.5rem;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}
.newsletterForm input::placeholder{
    font-family: 'Poppins', sans-serif;
    color: var(--midnight-blue);
}
.newsletterForm input:focus{
    outline: none;
}
.newsletterForm button{
    background-color: var(--blue-cyan);
    border-radius: 20px;
    color: var(--white-color);
    padding: 1rem 3rem;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.form-message{
    width: 100%;
    font-weight: 100;
    font-size: 0.8rem;
    margin-top: 1rem;
    text-align: right;
    color: #fff;
}


.mainCase-grid {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: 35rem 1fr;
    grid-template-rows: 1fr 1.875rem;
    grid-column-gap: 3.875rem;
    justify-items: flex-end;
    position: relative;
    z-index: 5;
}
.mainCase-grid-image{
    grid-area: 1 / 1 / 3 / 2;
}
.mainCase-grid-text{
    grid-area: 1 / 2 / 2 / 3;

}
.mainCase-grid-text h6{
    font-size: 1.25rem;
    color: var(--azure-blue);
    margin-bottom: 1.5rem;
}

.mainCase-grid-text p{
    font-size: 0.875rem;
}

.mainCase-grid-footer{
    grid-area: 2 / 2 / 3 / 3;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.mainCase-grid-image img{
    width: 100%;
    display: block;
}   

.mainCase-grid-capabilities-group {
    margin-top: 2rem;
    display: flex;
    column-gap: 0.75rem;
}

.mainCase-grid-capabilities-group .mainCase-capability {
    background-color: var(--navy-blue);
    border-radius: 10rem;
    color: var(--light-gray);
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    line-height: 1;
}

/*********** SuccessfulCollaborations ************/

.collaborationsWrapper{
    padding: 5rem 0;
    background-color: var(--midnight-blue);
    color: var(--white-color);
}

.collaborationsWrapper h3{
    text-align: center;
}

.collaboration-logos{
    width: 90%;
    margin: 4.5em auto 5.5em auto;
}
.collaboration-logos .swiper-slide{
    background-color: var(--midnight-blue);
}

.collaboration-logos img{
    display: block;
    width: 100%;
}

.collaboration-footer {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}

/*********** Contact Us ************/

.contactUsWrapper{
    padding: 5rem 0;
    position: relative;
}

.contactUsWrapper h3{
    text-align: center;
    color: var(--midnight-blue);
}
.contact-form-headline{
    box-sizing: border-box;
    width: 90%;
    max-width: 45rem;
    margin: 0 auto;
}

.contact-form-wrapper{
    box-sizing: border-box;
    width: 90%;
    margin: 4rem auto 0 auto;
    max-width: 64rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1.85rem;
    padding: 3.125rem;
}

.halfWidth{
    flex-basis: calc(50% - .75rem);
}
.fullWidth{
    flex-basis: 100%;
}

.contact-form-wrapper .MuiInputBase-root{
    background-color: var(--light-gray);
    border-radius: 7px 7px 7px 7px;
    color: var(--midnight-blue);
    padding: 0;
}

.contact-form-wrapper .MuiOutlinedInput-input{
    padding: 1.25rem 1.43rem;
}
.contact-form-wrapper .MuiOutlinedInput-notchedOutline{
    border: none;
    box-sizing: border-box;
}
.contact-form-wrapper .MuiInputLabel-root{
    transform: translate(1.35rem, 1.25rem) scale(1);
    color: var(--midnight-blue);
}

.contact-form-wrapper .MuiInputLabel-root.MuiInputLabel-shrink{
    transform: translate(10px, -18px) scale(0.75);
    color: var(--royal-purple);
    font-weight: 600;
}

.contactUsWrapper .baseButton{
    color: #FFF;
}

.alert-message{
    width: 100%;
    font-size: 14px;
}

.success{
    color: var(--green-sea);
}

.fail{
    color: var(--crimson-red);
}

/*********** Footer ************/

footer{
    background-color: var(--royal-purple);
    color: var(--white-color);
}
.footerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 4.8rem 0;
    align-items: center;
}

.footerLogo{
    width: 8rem;
    display: block;
    margin-bottom: 1.75rem;
    filter: invert(1);
}

.footerLogo img{
    width: 100%;
    display: block;
}

.social-list-wrapper{
    display: flex;
    column-gap: 1.8rem;
}

.social-list-wrapper li {
    width: 2.8rem;
    height: 2.8rem;
    background-color: var(--vivid-purple);
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.social-list-wrapper li svg{
    width: 41.6%;
}

.social-icon{
    cursor: pointer;
}

.company-info-section{
    text-align: center;
    position: relative;
}
.company-info-section p{
    font-weight: 200;
}
.company-info-section p span{
    font-weight: 400;
}
.designT{
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: right;
    font-weight: 200;
    font-size: .85em;
}



.blur-circle{
    border-radius: 50%;
    filter: blur(200px);
    position: absolute;
    left: 50%;
}
.circle-orange{
    background-color: var(--tangerine-orange);
}
.circle-pink{
    background-color: var(--orchid-pink);
}
.circle-violet{
    background-color: var(--vivid-purple);
}
.circle-blue{
    background-color: var(--sky-blue);
}


@media (max-width: 1200px) {

    .service-card-wrapper .capabilities-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .servicesRootWrapper .swiper-wrapper .swiper-slide{
        height: auto;
        width: 18rem;
    }

    .servicesRootWrapper .swiper-slide.swiper-slide-active {
        width: 48rem;
        transition: width ease-in .25s;
    }
    .service-card-wrapper {
        width: 48rem;
    }
    
    .mainCase-grid {
        grid-template-columns: 25rem 1fr;
        grid-row-gap: 1.5rem;
        grid-column-gap: 2.5rem;
    }
    .mainCase-grid-image {
        grid-area: 1 / 1 / 2 / 2;
    }
    .testimonial-bubble {
        height: 18rem;
    }
    .newsletterForm button{
        margin-top: 1rem;
    }
}

@media (max-width: 900px) {
	body{
        font-size: 15px;
    }
    ul.navBarList {
        position: fixed;
        box-sizing: border-box;
        height: 100%;
        /* height: calc( 100vh - var(--navbar-heigh) ); */
        width: 100%;
        padding: 3rem 3rem 0;
        top: var(--navbar-heigh);
        flex-direction: column;
        background-color: #fff;
        left: 100%;
        z-index: -1;
        transition: left 150ms ease-in-out;
    }
    ul.navBarList.open {
        left: 0;
    }


    .submenu{
        height: auto;
        max-height: 500px;
        transform: scaleY(1);
        opacity: 1;
        transition:  max-height 150ms ease-in, opacity 250ms ease-in, transform 50ms ease-in ;
        transform-origin: top center;
        
        width: 100%;
        position: relative;
        left: 1rem;
        top: 1.5rem;
        margin-bottom: 2rem;
        padding: 0rem;
        box-shadow: none;
    }
    .submenu li:hover a{
        color: var(--orchid-pink);
    }
    .submenu li{
        margin-bottom: 1rem;
        list-style: disc;
    }
    .submenu li a{
        color: var(--midnight-blue);
        font-size: 0.875rem;
    }

    .menu-bar-wrapper{
        display: block;
    }
    ul.navBarList > li {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .navBarList-child {
        width: 100%;
        padding: 5rem 0;
        position: relative;
        left: 0;
        top: 0;
        padding: 0rem;
        box-shadow: none;
    }
    ul.navBarList > li.active:hover .navBarList-child{
        padding: 1.5rem 1rem;
    }

        ul.navBarList > li:hover .navBarList-child{
        padding: 1.5rem 1rem;
    }


    .containerFull {
        width: 80%;
    }
    .service-card-wrapper{
        flex-direction: column;
        width: 100%;
    }
    .servicesRootWrapper .swiper-wrapper .swiper-slide {
        width: 26rem;
    }
    .service-card-wrapper .service-card-overview {
        width: 100%;
        padding: 1.55rem 2rem 1rem 2rem;
    }

    .service-card-wrapper .service-card-capabilities {
        width: 100%;
        padding: 1rem 2rem 5rem 2rem;
    }

    .mainCase-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto auto;
        grid-column-gap: 0;
    }
    .mainCase-grid-text {
        grid-area: 2 / 1 / 3 / 2;
    }
    .mainCase-grid-footer {
        grid-area: 3 / 1 / 4 / 2;
    }
    .mainCase-grid-image{
        width: 100%;
    }
    .newsletterWrapper{
        text-align: center;
    }
    .newsletterWrapper .containerFull{
        flex-direction: column;
    }
    .newsletterForm{
        width: 100%;
        margin-top: 3rem;
    }
    .newsletterForm form{
        justify-content: center;
    }
    .newsletterForm p{
        text-align: center;
    }
    .footerWrapper {
        flex-direction: column;
        row-gap: 2rem;
    }
    .designT{
        text-align: center;
    }
}

@media (max-width: 600px) {
    body{
        font-size: 14px;
    }
    .containerFull {
        width: 88%;
    }
    .heroImageWrapper {
        width: 80%;
    }
    .heroTextWrapper h2 {
        line-height: 1.05;
    }
    .heroSlide-1 {
        flex-direction: column-reverse;
        row-gap: 2rem;
    }
    .heroTextWrapper {
        width: 100%;
    }

    .servicesRootWrapper .swiper-wrapper .swiper-slide {
        width: 18rem;
    }
    .servicesRootWrapper .swiper-wrapper .swiper-slide .service-icon {
        width: 3.5rem;
        height: 3.5rem;
        margin-bottom: 1.75rem;
    }

    .service-card-capabilities h5, .service-card-wrapper .capabilities-grid{
        display: none;
    }
    .service-card-wrapper .service-card-capabilities{
        padding-bottom: 4rem;
    }

    .event-grid-item {
        grid-template-columns: 1fr;
        grid-template-rows: 20rem auto auto;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    .event-grid-image {
        grid-area: 1 / 1 / 2 / 2;
    }
    ul.event-grid-info {
        grid-area: 3 / 1 / 4 / 2;
    }
    .event-grid-description {
        grid-area: 2 / 1 / 3 / 2;
        margin: -3rem 2rem 2rem;
    }
    .event-grid-date {
        right: 1rem;
        top: -2rem;
        width: 5rem;
        height: 5rem;
    }
    .event-grid-month {
        font-size: 1.2rem;
    }
    .event-grid-day {
        font-size: 2rem;
    }
    .mainCase-grid-capabilities-group {
        flex-wrap: wrap;
        row-gap: 0.75rem;
    }
    .testimonial-bubble {
        height: 20rem;
    }
    .halfWidth{
        flex-basis: 100%;
    }
    .newsletterForm form{
        justify-content: center;
    }
    .newsletterForm button{
        margin-top: 1.5rem;
    }
}
