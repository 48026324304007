/******** PAYMENT HEADER ********/

.spa-header{
    height: auto;
    background: var(--blue-black);
    position: relative;
    color: var(--white-color);
    font-weight: 100;
}

.spa-header .containerFull{
    height: 100%;
    position: relative;
}

.spa-header-wrapper{
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    padding: 8rem 0;
}

.spa-header-title h1{
    font-size: 3.5rem;
    font-weight: 600; 
    line-height: 1.2;
    margin-bottom: 0;
}

.spa-header-title span{
    font-size: 3rem;
    font-weight: 100;
    font-style: italic;
}

.spa-header-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.content-header-left{
    width: 45%;
    padding-right: 2rem;
}

.content-text p{
    font-size: 1rem;
    line-height: 2.3;
    margin-top: 3rem;
}

.contact-block{
    width: 45%;
}

.contact-block p{
    margin-top: 8rem;
    font-size: 1.5rem;
    font-weight: 400;
}

.project-header-button{
    margin-top: 2rem;
    line-height: 1;
    display: flex;
    column-gap: 0.35rem;
    box-shadow: 10px 10px 20px 0px rgb(16 24 40 / 15%);
    border-radius: 3rem;
    cursor: pointer;
}

.project-header-button .thinButton{
    color: var(--white-color);
    background: var(--gradient-water-blue);
    padding: .6rem 2.5rem;
}

.header-image{
    width: 45%;
    position: relative;
}

.phone-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.phone-container img{
    width: 90%;
    position: absolute;
    right: 0;
}

.project-creation{
    margin-top: 30rem;
    left: 0;
    width: 100%;
    height: 100%;
}

.project-creation-title{
    width: 40%;
}

.project-creation-title h1{
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0;
}

.project-creation-title span{
    font-size: 2.5rem;
    font-weight: 100;
    font-style: italic;
}

.project-creation-content{
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project-list{
    width: 50%;
}

.project-list-title{
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0;
    display: flex;
    position: relative;
    align-items: baseline;
}

.project-list-item-number{
    width: 50px;
    position: relative;;
    font-size: 4rem;
    font-weight: 700;
    margin-left: -8%;
    opacity: 0.2;
    text-align: right;
}


.project-list-item-text{
    width: 100%;
    font-size: 1.5rem;
    font-weight: 600;
    opacity: 0.8;
}

.project-list-content p{
    margin-top: -1em;
    line-height: 1.8rem;
}

.technologies-used{
    width: 45%;
    margin-top: 10%;
    padding-top: 3rem;
    text-align: center;
}

.technologies-title{
    margin: 0 2rem;
}

.technologies-title h3{
    font-weight: 400;
    margin-bottom: 2rem;
}

.technologies-image{
    width: 100%;
    margin-top: 5rem;
}

.technologies-image img{
    width: 90%;
}

.gradient{
    position: absolute;
}

.gradient img{
    width: 100%;
}

.g-1{
    width: 70%;
    top: 10%;
    right: -40%;
}

.g-2{
    width: 70%;
    top: 8%;
    left: -50%;
}

.g-3{
    width: 70%;
    top: 40%;
    left: -40%;
}



/******** DEVELOPMENT PROCESS ********/


.expertise-services {
    position: relative;
    background-color: var(--white-color);
    overflow: hidden;
    padding: 5rem 0;
}

.expertise-services-wrapper {
    margin: 3rem auto 0;
    position: relative;
    z-index: 2;
    color: var(--black-color);
}

.development-title h3{
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0;
}

.development-title p{
    font-size: 1.2rem;
    font-weight: 100;
    font-style: italic;
}

.process-grid{
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.process-item{
    margin-bottom: 3rem;
    padding: 5rem 3rem 0;
    box-shadow: 10px 10px 20px 0px #00000055;
}
.process-item-text h4{
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.process-item-text p{
    font-size: .9rem;
}


.planning{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-image: url(../../images/spa-live/planning-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.planning .process-item-image img{
    height: 100%;
}

.design{
    width: 45%;
    height: 30rem;
    justify-content: space-between;
    background-image: url(../../images/spa-live/design-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}
.design .process-item-image img{
    margin-top: 2rem;
}


.development{
    width: 38%;
    height: 38rem;
    justify-content: space-between;
    background-image: url(../../images/spa-live/development-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}
.development .process-item-image{
    margin-top: 3rem;
    position: absolute;
    left: 0;
    width: 100%;
}

.development .process-item-image img{
    position: relative;
    left: 0;
}


.evaluation{
    width: 45%;
    height: 35rem;
    margin-top: -8rem;
    justify-content: space-between;
    background-image: url(../../images/spa-live/evaluation-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}
.evaluation .process-item-image{
    position: absolute;
    left: 0;
    width: 100%;
}
.evaluation .process-item-image img{
    width: 100%;
}

.implementation{
    width: 38%;
    height: 45rem;
    justify-content: space-between;
    background-image: url(../../images/spa-live/implementation-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.implementation .process-item-image{
    position: absolute;
    left: 0;
    bottom: -1rem;
    width: 100%;
}
.implementation .process-item-image img{
    width: 100%;
}

.like-process{
    width: 40%;
    margin-top: -10%;
}

.like-process h3{
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1.5;
}
.like-process p{
    font-size: 1.5rem;
    font-weight: 100;
    font-style: italic;
}


.button-wrapper{
    width: 100%;
}

.process-button{
    margin: 7rem auto;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
}

.process-button .thinButton{
    color: var(--white-color);
    background: var(--gradient-water-blue);
    padding: .6rem 2.5rem;
}

@media screen and (max-width: 1250px){
    .project-creation-title{
        width: 100%;
    }

    .project-list-content p{
        margin-top: 0;
    }

    .design{
        width: 38%;
    }
    .evaluation{
        width: 38%;
    }

    .like-process{
        margin-top: -20%
    }

}


@media (max-width: 1000px ) {
    .project-creation{
        margin-top: 15rem;
    }

    .project-creation-content{
        margin-top: 2rem;
    }

    .project-list{
        width: 100%;
    }

    .project-list-item-number{
        margin-left: 0;
    }

    .technologies-used{
        width: 100%;
        margin-top: 5rem;
    }

    .technologies-title{
        width: 100%;
        margin: 0;
    }

    .technologies-image{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
    }

    .planning{
        display: flex;
        flex-direction: column;
    }
    .planning .process-item-image{
        justify-content: center;
    }
    .planning .process-item-image img{
        margin: 2rem auto 0;
    }

    .design{
        width: 100%;
    }
    .development{
        width: 100%;
    }
    .evaluation{
        width: 100%;
    }
    .implementation{
        width: 100%;
    }
    .implementation .process-item-image{
        width: 80%;
    }

    
    .like-process{
        width: 100%;
        margin-top: 0%;
        text-align: center;
    }

    .process-button{
        margin: 3rem auto;
    }

}


@media (max-width: 700px){
    .content-header-left{
        width: 100%;
        padding-right: 0;
    }

    .header-image{
        display: none;
    }

    .contact-block{
        width: 100%;
        margin-top: 0;
        text-align: center;
    }
    .project-header-button .thinButton{
        margin: auto;
    }

    .process-item{
        margin: 0 0 3rem;
    }

    .evaluation .process-item-image{
        bottom: -1rem;
    }

    .implementation .process-item-image{
        width: 100%;
    }
}


