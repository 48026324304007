/* Main Colors */
:root {

  --radius20: 1.25rem; 
  --white-color: #fff;
  --black-color: #000;
  --black-gray: #494949;
  --midnight-blue: #222F3E;
  --navy-blue: #3E5266;
  --royal-blue: #0362D1;
  --sea-blue: #0077BB;
  --pale-blue: #CBD2DE;
  --deep-blue: #002136;
  --dark-blue: #020058;
  --night-blue: #0B093D;
  --bridge-blue: #141F33;
  --royal-purple: #341F97;
  --blue-purple: #4B0082;
  --blue-black: #02171F;
  --pine-purple: #9759A5;
  --heart-purple: #5F27CD;
  --vivid-purple: #6C5CE7;
  --sunset-gold: #FECA57;
  --brown-yellow: #D8B95E;
  --tangerine-orange: #FF9F43;
  --orange-color: #E67F08;
  --orange-brown: #975D2A;
  --chocolate-brown: #6C554C;
  --beige-yellow: #E6DDC9;
  --green-mint: #1DD1A1;
  --green-aqua: #3BDABB;
  --green-sea: #2ECC71;
  --green-teal: #82D0D3;
  --azure-blue: #74B9FF;
  --sky-blue: #48DBFB;
  --blue-cyan: #81ECEC;
  --light-water: #A9E7FC;
  --water-welt: #3D97B2;
  --formal-blue: #A4CFE5;
  --light-purple: #A29BFE;
  --wine-purple: #A20461;
  --purple-pink: #E400DD;
  --orchid-pink: #F368E0;
  --rose-red: #E84393;
  --coral-red: #FF5B5B;
  --crimson-red: #EE5253;
  --vivid-red: #E40002;
  --slate-gray: #718096;
  --medium-gray: #DEDEDE;
  --soft-beige: #AFADA9;
  --light-gray: #F4F5F7;
  --bone-white: #f6f5f0;


  --logo-white: invert(1);
  --logo-black: invert(0);

  --navbar-heigh: 7.35rem;

  --gradient-purple-pink-orange: linear-gradient(90deg, 
  #6C5CE7, 
  #F368E0 43.83%, 
  #E84393 64.38%, 
  #EE5253 83.33%, 
  #FF9F43 96.88%
  );

  --gradient-pink-orange: linear-gradient(90deg, 
  #F368E0 0%, 
  #E84393 50%, 
  #FF9F43 100%
  );

  --gradient-orange-pink: linear-gradient(to left, 
  #F368E0 0%, 
  #E84393 50%, 
  #FF9F43 100%
  );

  --gradient-blue-green-orange:linear-gradient(to right, 
  #48DBFB 4.8%, 
  #2ECC71 19.8%, 
  #FECA57 50.5%, 
  #FF9F43 78.1%, 
  #EE5253 95.2%
  );

  --gradient-blue-violet: linear-gradient(to right, 
  #48DBFB -20%, 
  #7F56D9 70%
  );

  --gradient-water-blue: linear-gradient(to right, 
  #A8E6FC -20%, 
  #3A94B0 70%
  );

  --gradient-dark-blue: linear-gradient(to right, 
  #0072FB -20%, 
  #013A87 70%
  );

  --gradient-sea-blue: linear-gradient(to top right,
  #0077BB 50%,
  #0078bd81 90%
  );

   --gradient-blue-white: linear-gradient(to right, 
  #062f64  20%, 
  #FFFFFF 80%
  );

  --gradient-blue-violet-red: linear-gradient(90deg, 
  #48DBFB, 
  #7F56D9 32.81%, 
  #F368E0 51.04%, 
  #E84393 66.15%, 
  #ED505A 78.64%
  );

  --gradient-orange-violet-pink: linear-gradient(90deg, 
  #FF9F43 6.05%, 
  #EE5253 23.44%, 
  #74B9FF 58.98%, 
  #E84393 81.42%, 
  #F368E0 96.88%
  );

  --gradient-pink-violet-blue: linear-gradient(to right, 
  #F368E0 0%, 
  #6C5CE7 25.52%, 
  #0984E3 52.60%, 
  #0ABDE3 72.92%, 
  #00CEC9 100%);

  --gradient-blue-violet-pink: linear-gradient(to right, 
  #0ABDE3 25.61%, 
  #74B9FF 52.17%, 
  #A29BFE 76.89%, 
  #F368E0 100.14%);

  --gradient-blue-violet-pink-balanced: linear-gradient(to right, 
  #0ABDE3 12.83%, 
  #6C5CE7 48.33%, 
  #F368E0 89.12%);

  --gradient-orange-pink-violet-blue: linear-gradient(115deg, 
  #FF9F43 10%, 
  #F368E0 37.55%, 
  #6C5CE7 70.58%, 
  #0ABDE3 90%);

  --gradient-green-darkviolet: linear-gradient(to right, 
  #1DD1A1, 
  #341F97 34.0683%,
  #222F3E 62.9529%);

  --gradient-darkviolet-green: linear-gradient(to left, 
  #1DD1A1, 
  #341F97 34.0683%,
  #222F3E 62.9529%);

  --gradient-green-violet: linear-gradient(to left, 
  #6C5CE7 2.18%, 
  #48DBFB 43.97%, 
  #55EFC4 76.85%);

  --gradient-violet-green: linear-gradient(to right, 
  #6C5CE7 2.18%, 
  #48DBFB 43.97%, 
  #55EFC4 76.85%);

  --gradient-pink-green-orange: linear-gradient(to right, 
  #F368E0 0%, 
  #43E8CA 50%, 
  #FF9F43 100%
  );

  --gradient-pink-blue-violet: linear-gradient(to right, 
  #F368E0 10%, 
  #48DBFB 35%, 
  #A29BFE 70%,
  #6C5CE7 90%
  );

  --gradient-violet-pink-red: linear-gradient(to right, 
  #6C5CE7 2.18%, 
  #F368E0 43.97%, 
  #E84393 76.85%);

  --gradient-violet-pink-red-orange: linear-gradient(to right, 
  #6C5CE7 10%, 
  #F368E0 35%, 
  #E84393 70%,
  #FF9F43 90% );

  --gradient-vertical-green-violet: linear-gradient(180deg, 
  #1DD1A1 4.96473%, 
  #341F97 61.6872%);

  --gradient-vertical-pink-violet:  linear-gradient(180deg, 
  #F368E0 50%, 
  #6C5CE7 50%);

  --gradient-blue-orange-pink: linear-gradient(to right, 
  #3498DB 2.18%, 
  #FF9F43 43.97%, 
  #FD79A8 76.85%);

  --gradient-red-blue-orange-pink: linear-gradient(to right, 
  #E84393 10%, 
  #3498DB 35%, 
  #FF9F43 70%,
  #F368E0 90%);

  --gradient-violet-red-orange: linear-gradient(to right, 
  #6C5CE7 2.18%, 
  #EE5253 63.97%, 
  #FF9F43 86.85%);

  --gradient-violet-pink-blue-orange-red: linear-gradient(to right, 
  #6F5CE7 0%, 
  #F368E0 25.52%, 
  #5E98FD 52.60%, 
  #FF9F43 72.92%, 
  #E84393 110%);

  --gradient-pink-dark-blue: linear-gradient(180deg, 
  #F368E0 4.96473%, 
  #001D37 61.6872%);

}

.midnightkBttn{
  background-color: var(--midnight-blue);
  color: var(--white-color);
}

.pinkOrangeGradientBttn{
  background: var(--gradient-pink-orange);
  color: var(--white-color);
}
.orangePinkGradientBttn{
  background: var(--gradient-orange-pink);
  color: var(--white-color);
}
.orangePinkGradientBttn{
  background: var(--gradient-orange-pink);
  color: var(--white-color);
}
.blueVioletPinkBttn{
  background: var(--gradient-blue-violet-pink);
  color: var(--white-color);
}
.blueOrangePinkBttn{
  background: var(--gradient-blue-violet-pink);
  color: var(--white-color);
}
/******* Gradients Texts *******/

.text-pink-orange{
  background: var(--gradient-pink-orange);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-orange-pink{
  background: var(--gradient-orange-pink);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-blue-violet-red {
  background: var(--gradient-blue-violet-red);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-orange-violet-pink {
  background: var(--gradient-orange-violet-pink);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-blue-violet-pink {
  background: var(--gradient-blue-violet-pink);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-violet-green {
  background: var(--gradient-violet-green);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-blue-white {
  background: var(--gradient-blue-white);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-green-violet {
  background: var(--gradient-green-violet);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-orange-pink-violet-blue{
  background: var(--gradient-orange-pink-violet-blue);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-pink-blue-violet{
  background: var(--gradient-pink-blue-violet);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-violet-pink-red{
  background: var(--gradient-violet-pink-red);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-violet-pink-red-orange{
  background: var(--gradient-violet-pink-red-orange);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-water-blue{
  background: var(--gradient-water-blue);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-red-blue-orange-pink{
  background: var(--gradient-red-blue-orange-pink);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-violet-pink-blue-orange-red{
  background: var(--gradient-violet-pink-blue-orange-red);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

:root {
  @media ( max-width : 600px ) {
    --navbar-heigh: 5.25rem;
  }
}