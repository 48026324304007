.software-design-header {
    background-color: var(--bone-white);
    position: relative;
    box-sizing: border-box;
    padding: 6rem 0 18rem 0;
    z-index: 2;

}

.software-layout{
    position: absolute;
    margin: auto;
    height: 100%;
    top: 0;
    left: 3rem;
    right: 0;
    height: 100%;
    z-index: -1;
}

.software-layout-container{
    width: 63%;
    background-color: #F6F1EB;
    height: 100%;
    margin-left: auto;
    display: flex;
    justify-content:flex-end;
    align-items: flex-end;
}
.software-layout-container img{
    width: 82.8%;
    display: block;
}

.software-design-label {
    display: flex;
    align-items: center;
    column-gap: 2.5rem;
}
.software-design-icon {
    flex: 0 0 5rem;
    width: 5rem;
    height: 5rem;
    background-color: var(--white-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
}
.software-design-icon svg {
    width: 2.4rem;
}

.software-design-label h2 {
    color: var(--soft-beige);
}
.software-design-headline {
    margin-top: 7rem;
}
.software-design-headline h1 {
    font-size: 4.375rem;
    line-height: 1.35;
    color: var(--might-blue);
}

.software-design-headline h1 span.h1-thin{
    font-weight: 400;
}

.software-design-headline h1 span.h1-box{
    background-color: #FFF;
    color: var(--green-teal);
    line-height: 1;
    padding: 0.25rem 1.25rem;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 10%);
    position: relative;
    top: -1.5rem;
    left: -1rem;
    transform: rotate(-10deg);
    display: inline-block;
    transform-origin: top right;
    animation: box-hanging 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


/******** EXPERTISE & SERVICES ********/

.software-services {
    position: relative;
    background-color: var(--white-color);
    overflow: hidden;
    padding: 5.5rem 0 22.68rem 0;
    background-image: url(../../images/bgr-software-service.webp);
    background-position: center center;
    background-size: cover;
}

.software-layout-bottom {
    position: absolute;
    margin: auto;
    height: 100%;
    top: 0;
    left: 3rem;
    right: 0;
    height: 100%;
}
.software-layout-bottom-container {
    width: 63%;
    height: 100%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.software-layout-bottom-container img {
    width: 82.8%;
    display: block;
}


.software-services-wrapper {
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.software-services-wrapper h3{
    color: var(--might-blue);
    line-height: 1.65;
}

.software-services-wrapper h3 span{
    background-color: #FFF;
    color: var(--green-teal);
    line-height: 1;
    padding: 0.5rem 1.25rem;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 10%);
    position: relative;
    top: 0rem;
    left: 0rem;
    transform: rotate(-7deg);
    display: inline-block;
}

.software-services-grid {
    margin-top: 3.75rem;
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-rows: repeat(6, 5.625rem);
    grid-column-gap: 1.25rem;
    grid-row-gap: 1rem;
}

.software-services-item-1 {
    grid-area: 1 / 1 / 2 / 2;
}
.software-services-item-2 {
    grid-area: 2 / 1 / 3 / 2;
}
.software-services-item-3 {
    grid-area: 3 / 1 / 4 / 2;
}
.software-services-item-4 {
    grid-area: 4 / 1 / 5 / 2;
}
.software-services-item-5 {
    grid-area: 5 / 1 / 6 / 2;
}
.software-services-item-6 {
    grid-area: 6 / 1 / 7 / 2;
}


.software-services-content {
    grid-area: 1 / 2 / 7 / 3;
}

.software-services-item {
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    background-color: var(--white-color);
}
.software-services-text {
    position: relative;
    box-sizing: border-box;
    
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 2.68rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--navy-blue);
    transition: color 250ms ease-in;
    column-gap: 1rem;
}
.software-services-item .software-services-ico {
    width: 2.75rem;
    height: 2.75rem;
    font-size: .65rem;
    font-weight: 200;
    text-align: center;
}

.software-services-item .software-services-ico img{
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.software-services-item:hover, .software-services-item.active{
    background-color: var(--navy-blue);
    box-shadow: 20px 20px 20px rgb(0 0 0 / 20%);
}
.software-services-item:hover .software-services-text, .software-services-item.active .software-services-text{
    color: var(--white-color);
}

.software-services-item:hover .software-services-ico img, .software-services-item.active .software-services-ico img{
    filter: brightness(100);
}

.software-services-content{
    display: flex;
    opacity: 0;
}
.software-services-content.active{
    opacity: 1;
}

.software-services-content-text{
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: rgb(34 47 62 / 15%);
    backdrop-filter: blur( 20px );
    padding: 7% 10.14%;
    text-align: center;
    color: var(--white-color);
}

.software-services-content p{
    line-height: 2.2;
    opacity: 0;
    transition: opacity 150ms ease-in;
}

.software-services-content.active p{
    opacity: 1;
}

@keyframes box-hanging {
    0%{
        transform: rotate(-15deg);
    }
    50%{
        transform: rotate(-20deg);
    }
    100%{
        transform: rotate(-15deg);
    }
}

.software-design-headline h1 span.h1-vision{
    font-weight: 400;
    font-family: 'Reenie Beanie', cursive;
    font-size: 8rem;
    line-height: 0;
}

/******** TOOLS ********/

.software-tools-wrapper{
    position: relative;
    height: 50rem;
}

.software-tool-headline {
    position: absolute;
    top: -9.5rem;
    left: 0;
    z-index: 5;
}

.software-tool-headline h1 {
    line-height: 1;
    font-size: 12.6rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--white-color);
    color: rgb(255 255 255 / 0%);
}
.software-platforms-container{
    position: absolute;
    top: -2.5rem;
    right: 22.8%;
    box-sizing: border-box;
    width: 32.75rem;
    height: 32.75rem;
    border: dashed var(--soft-beige) 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3.75rem;
}

.software-platform-headline p{
    text-align: center;
    font-weight: 200;
}

.software-platform-headline span{
    font-size: 1.25rem;
    font-weight: 600;
}

.software-tool{
    position: absolute;
    font-family: 'Reenie Beanie', cursive;
    width: 5.5rem;
    border-radius: 10px;
    z-index: 10;
}

.software-tool .software-tool-copy{
    position: absolute;
}

.software-tool .software-tool-copy p{
    font-size: 1.25rem;
    line-height: 1;
}

.software-tool img {
    display: block;
    width: 100%;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
}

.software-tool h5 {
    font-size: 1.875rem;
    font-weight: 200;
    text-align: center;
}

.software-tool-figma{
    top: 0.85rem;
    left: 3.75rem;
}

.software-tool-figma .software-tool-copy{
    top: 3rem;
    left: -24.5rem;
    width: 22.55rem;
}

.software-tool-invision{
    top: 0.85rem;
    left: 23.75rem;
}

.software-tool-invision .software-tool-copy{
    top: 3rem;
    left: 8rem;
    width: 18.75rem;
}

.software-tool-excalidraw {
    top: 14rem;
    left: 30.5rem;
}

.software-tool-excalidraw .software-tool-copy{
    top: 10rem;
    left: 4rem;
    width: 16.55rem;
}

.software-tool-balsamiq {
    top: 29.5rem;
    left: 14rem;
}

.software-tool-balsamiq .software-tool-copy{
    top: 11rem;
    left: -5rem;
    width: 16.55rem;
}

.software-tool-eraserio {
    top: 14rem;
    left: -3.5rem;
}

.software-tool-eraserio .software-tool-copy{
    top: 10rem;
    left: -15rem;
    width: 20rem;
}

/******** CHOOSE INDUSTRY  ********/

.software-industry{
    position: relative;
    padding: 0;
    background-color: var(--bone-white);
    overflow: hidden;
}

.software-industry-wrapper{
    padding: 5rem 0 6rem 0;
}

.software-industry h3{
    color: var(--deep-blue);
    position: relative;
    display: inline-block;
    z-index: 1;
}

.software-industry h3 span{
    background-color: #FFF;
    color: var(--green-teal);
    line-height: 1;
    padding: 0.5rem 1.25rem;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 10%);
    position: relative;
    top: 0rem;
    left: 0rem;
    transform: rotate(5deg);
    display: inline-block;
}

.software-listNavigator{
    display: flex;
    width: 6.5rem;
    margin-left: auto;
    justify-content: space-between;
}

.software-list-arrow{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.software-list-arrow circle{
    fill: var(--bone-white);
    stroke: var(--deep-blue);
    stroke-width: 2px;
    transition: all ease-in 150ms;
}
.software-list-arrow path{
    stroke: var(--deep-blue);
    transition: all ease-in 150ms;
}

.software-list-arrow:hover circle{
    fill: var(--green-teal);
}


.software-list-forward{
    transform: rotate(180deg);
}

.software-industries-list{
    margin-top: 2rem;
}

.software-industries-list .swiper{
    overflow: visible;
}

.software-industries-list .swiper-wrapper .swiper-slide {
    background-color: var(--white-color);
    box-sizing: border-box;
    width: 18.5rem;
    height: 24.3rem;
    position: relative;
    cursor: pointer;
    padding: 0;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 15%);
}

.software-industries-list .industry-selector{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
}
.industry-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    background-color: var(--black-color);
    opacity: 0.4;
    transition: opacity 150ms ease-in;
}

.swiper-slide.active .industry-overlay{
    opacity: 0;
}

.software-industries-list h5 {
    display: inline-table;
    position: absolute;
    padding: 1rem 1rem;
    border-radius: 10px;
    text-align: center;
    bottom: 0;
    left:0;
    right: 0;
    margin: auto;
    background-color: rgb(255 255 255 / 0%);
    z-index: 5;
    font-family: 'Reenie Beanie', cursive;
    font-weight: 200;
    font-size: 3.5rem;
    color: var(--white-color);
    line-height: 1;
    transition: background-color 250ms ease-in, right 250ms ease-in, bottom 250ms ease-in, color 250ms ease-in ;
}

.swiper-slide.active h5 {
    background-color: var(--white-color);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2.18rem;
    color: var(--green-teal);
    bottom: 2rem;
    transform: rotate(-6deg);
    right: -1rem;
    left: auto;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 15%);
}

.software-industries-list h5 span{
    font-family: 'Reenie Beanie', cursive;
    font-weight: 100;
    font-size: 2.25rem;
}
.software-industries-list img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}


/******** SOLUTIONS ********/

.software-solutions {
    position: relative;
    background: var(--bone-white);
    overflow: hidden;
    padding: 5rem 0 8rem 0;
    
}
.software-solutions h3{
    text-align: center;
    margin-bottom: 4rem;
}
.software-solutions-wrapper {
    margin: 0 auto;
    max-width: 58.75rem;
    position: relative;
    z-index: 2;
    color: var(--deep-blue);
}

.software-solutions-list-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
}

.software-solutions-item h6{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.solutions-grid-capabilities-group {
    display: flex;
    gap: 0.75rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
}

.solutions-grid-capabilities-group .solutions-capability {
    background-color: var(--green-teal);
    border-radius: 10rem;
    color: var(--white-color);
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    line-height: 1;
}

.software-industry .case-software-design h3{
    color: var(--green-teal);
}

.case-software-design .see-more-btn span{
    color: var(--green-teal);
}

.case-software-design .see-more-btn svg{
    fill: var(--green-teal) !important;
}


@media (max-width: 1200px) {
    .software-design-headline {
        margin-top: 3.5rem;
    }
    .software-design-headline h1 {
        font-size: 4rem;
    }

    .software-layout-container img {
        width: 80%;
        margin-right: 2rem;
    }

    .software-layout-bottom-container img {
        width: 80%;
        margin-right: 2rem;
    }

    .software-services-text {
        padding: 1rem 1rem;
        font-size: 1.25rem;
        column-gap: 0.75rem;
    }
    .software-services-item .software-services-ico {
        width: 3rem;
    }
    .software-services-content p {
        line-height: 2;
    }

    .software-tools-wrapper {
        height: 54rem;
    }

    .software-platforms-container {
        width: 28rem;
        height: 28rem;
        border: dashed var(--soft-beige) 1px;
        top: 8.8rem;
        left: 0;
        right: 0;
        margin: auto;
    }
    .software-platform-headline p {
        font-size: .85rem;
    }
    .software-tool {
        width: 4.25rem;
    }

    .software-tool-figma .software-tool-copy {
        top: -3rem;
        left: -16.5rem;
        width: 16.55rem;
    }
    .software-tool-invision {
        left: 19.75rem;
    }
    .software-tool-invision .software-tool-copy {
        top: -3rem;
        left: 7rem;
        width: 14rem;
    }
    .software-tool-excalidraw {
        top: 13.5rem;
        left: 26rem;
    }
    .software-tool-excalidraw .software-tool-copy {
        top: 8.5rem;
        left: 1.5rem;
        width: 14.55rem;
    }
    .software-tool-balsamiq {
        top: 26rem;
        left: 12rem;
    }
    .software-tool-balsamiq .software-tool-copy {
        top: 8.5rem;
        left: -5rem;
        width: 16.55rem;
    }
    .software-tool-eraserio {
        top: 13.5rem;
        left: -2.5rem;
    }
    .software-tool-eraserio .software-tool-copy {
        top: 8.5rem;
        left: -9rem;
        width: 14rem;
    }
}

@media ( max-width: 900px ) {
    
    .software-design-label h2 {
        font-size: 2.15rem;
    }
    .software-design-headline h1 {
        font-size: 3.15rem;
    }
    .software-design-headline h1 br{
        display: none;
    }
    .software-design-headline h1 span.h1-vision {
        font-size: 6rem;
    }

    .software-layout {
        left: 0;
    }

    .software-layout-container {
        width: 100%;
        background-color: var(--bone-white);
        justify-content: center;
    }
    
    .software-layout-container img {
        margin-right: 0;
    }
    .software-services {
        background-position: right top;
        background-size: 169rem auto;
    }

    .software-layout-bottom{
        display: none;
    }

    .software-services-grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, auto);
    }
    .software-services-item-1 { grid-area: 1 / 1 / 2 / 2; }
    .software-services-content-1 { grid-area: 2 / 1 / 3 / 2; }
    .software-services-item-2 { grid-area: 3 / 1 / 4 / 2; }
    .software-services-content-2 { grid-area: 4 / 1 / 5 / 2; }
    .software-services-item-3 { grid-area: 5 / 1 / 6 / 2; }
    .software-services-content-3 { grid-area: 6 / 1 / 7 / 2; }
    .software-services-item-4 { grid-area: 7 / 1 / 8 / 2; }
    .software-services-content-4 { grid-area: 8 / 1 / 9 / 2; }

    .software-services-item-5 { grid-area: 9 / 1 / 10 / 2; }
    .software-services-content-5 { grid-area: 10 / 1 / 11 / 2; }
    .software-services-item-6 { grid-area: 11 / 1 / 12 / 2; }
    .software-services-content-6 { grid-area: 12 / 1 / 13 / 2; }

    .software-services-item {
        height: 6rem;
    }

    .software-services-content{
        max-height: 0;
        opacity: 1;
        overflow: hidden;
        transition: max-height 150ms ease-in;
    }
    .software-services-content.active{
        opacity: 1;
        height: auto;
        max-height: 500px;
    }

    .software-services-text{
        padding: 1rem 2rem;
        font-size: 1.15rem;
    }
    .software-services-item .software-services-ico {
        width: 2.25rem;
    }
    .software-services-item:hover, 
    .software-services-item.active {
        box-shadow: 10px 10px 15px rgb(0 0 0 / 20%);
    }

    .software-tools-wrapper {
        height: auto;
        padding: 5rem 0;
    }
    .software-tool-headline {
        top: -8.5rem;
    }
    .software-tool-headline h1 {
        font-size: 10.6rem;
    }
    .software-platforms-container {
        padding: 0;
        width: 100%;
        height: auto;
        border: none;
        top: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }

    .software-platform-headline p {
        text-align: left;
    }

    .software-tool{
        position: relative;
        width: 100%;
        display: flex;
    }

    
    .software-tool .software-tool-copy {
        position: relative;
        flex: 1;
    }

    .software-tool-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.25rem;
        flex: 0 0 6.7rem;
    }

    .software-tool .software-tool-image img{
        width: 3.5rem;
        height: auto;
    }
    .software-tool-figma, .software-tool-figma .software-tool-copy,
    .software-tool-invision, .software-tool-invision .software-tool-copy,
    .software-tool-excalidraw, .software-tool-excalidraw .software-tool-copy,
    .software-tool-balsamiq, .software-tool-balsamiq .software-tool-copy,
    .software-tool-eraserio, .software-tool-eraserio .software-tool-copy{
        left: 0;
        top: 0;
        width: 100%;
    }
}



@media (max-width: 600px) {
    .software-design-header {
        padding: 3.25rem 0 16.25rem 0;
    }
    .software-design-label {
        align-items: center;
        row-gap: 1rem;
        column-gap: 1rem;
    }
    .software-design-headline {
        margin-top: 2.5rem;
    }
    .software-design-label h2 {
        font-size: 1.75rem;
    }
    .software-design-icon {
        flex: 0 0 3.5rem;
        width: 3.5rem;
        height: 3.5rem;
    }
    .software-design-headline h1 {
        font-size: 3rem;
        line-height: 1.25;
    }

    .software-design-headline h1 span.h1-box {
        top: -0.5rem;
        left: 0rem;
    }

    @keyframes box-hanging {
        0%{
            transform: rotate(-5deg);
        }
        50%{
            transform: rotate(-8deg);
        }
        100%{
            transform: rotate(-5deg);
        }
    }


    .software-services-wrapper h3 {
        line-height: 1.25;
    }
    .software-services-wrapper h3 br{
        display: none;
    }
    .software-services-wrapper h3 span {
        top: .75rem;
        transform: rotate(-3deg);
    }
    .software-tool-headline {
        top: -4.75rem;
    }
    .software-tool-headline h1 {
        font-size: 6rem;
    }
    .software-tool {
        flex-direction: column;
        row-gap: 1rem;
    }
    .software-tool-image {
        flex-direction: row;
        column-gap: 1rem;
        flex: 1;
    }
    .software-tool .software-tool-image img {
        width: 2.75rem;
    }
    .software-services-item {
        height: 4.5rem;
    }
    .software-services-item .software-services-ico {
        width: 2rem;
        height: 2rem;
    }
    .software-services-text {
        padding: 1rem 1.5rem;
        font-size: 1.1rem;
    }

    .software-services {
        padding: 5.5rem 0 12.5rem 0;
    }

    .software-industries-list .swiper-wrapper .swiper-slide {
        width: 16rem;
    }
}