.nation-header{
    background: var(--black-color);
    position: relative;
    color: var(--white-color);
    height: auto;
    width: 100%;
    text-align: center;
}


.nation-header .g1{
    width: 100%;
    max-width: 1900px;
    position: absolute;
    z-index: 1;
}
.nation-header .g1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.gradient-wrapper{
    height: 100%;
    width: 100vw;
    margin: auto;
    max-width: 1920px;
    max-height: 1000px;
    z-index: 1;
}
.bgr-blur{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--white-color);
    opacity: 1%;
    mix-blend-mode: luminosity;
    filter: blur(50px); 
    z-index: 1;
}

.nation-header .containerFull{
    height: 100%;
    position: relative;
}

.nation-header-wrapper{
    width: 100%;
    height: 100%;
    padding: 15% 0 20%;
    display: flex;
    flex-direction: row;
    z-index: 2;
    position: relative;
}

.nation-header-title h1{
    font-size: 5rem;
    font-weight: 700;
    mix-blend-mode: soft-light;
}

.nation-header-title h2{
    font-size: 2.5rem;
    font-weight: 100;
    font-style: italic;
    margin-top: -1rem;
    color: var(--black-color);
}

.nation-header-title p{
    font-weight: 100;
    margin-top: 2rem;
    line-height: 2rem;
    text-align: left;
}

/* CHALLENGE */

.challenge-wrapper{
    position: relative;
}

.challenge-header{
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
}

.challenge-text{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 15% 5% 10%;
    z-index: 2;
}

.challenge-text h2{
    font-size: 2.5rem;
    font-weight: 400;
}
.challenge-text span{
    font-weight: 100;
    font-style: italic;
}
.challenge-text p{
    margin-top: 1.5rem;
    line-height: 1.7rem;
    font-weight: 100;
}

.challenge-img{
    width: 60%;
    height: 100%;
    position: absolute;
    right: -5%;
    z-index: 1;
}

.challenge-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.phone{
    position: absolute;
    top: 27%;
    right: 22%;
    height: 35rem;
    z-index: 2;
}
.phone img{
    height: 100%;
    object-fit: cover;
    object-position: center;
    rotate: -10deg;
}

.platform-wrapper{
    background-color: var(--blue-purple);
    color: var(--white-color);
    padding-top: 8%;
    height: 35rem;
}

.platform-wrapper .containerFull{
    position: relative;
}

.platform-text{
    width: 65%;
    margin: auto;
    text-align: center;
}

.platform-text h2{
    font-size: 2.5rem;
    font-weight: 400;
}

.platform-text p{
    margin-top: 1.5rem;
    line-height: 1.7rem;
    font-weight: 100;
}

.platform-img{
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: 4%;
}

.platform-img img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.bg-code{
    background-image: url(../../images/all-nation/code-bg.webp);
    object-fit: cover;
    object-position: center;
    height: 40rem;
}


/* DEPLOYMENT */

.deployment-wrapper{
    position: relative;
}

.deployment-header{
    position: relative;
    height: 49.5rem;
    width: 100%;
    display: flex;
}

.deployment-wrapper hr{
    margin: 0;
    margin-top: 21%;
    width: 100%;
    height: 1px;
    background: var(--blue-purple);
    position: absolute;
}

.deployment-text{
    width: 40%;
    margin-top: 18%;
    padding: 15% 0% 10%;
    z-index: 2;
}

.deployment-text h2{
    font-size: 2.5rem;
    font-weight: 400;
}
.deployment-text span{
    font-weight: 100;
    font-style: italic;
}
.deployment-text p{
    margin-top: 1.5rem;
    line-height: 1.7rem;
    font-weight: 100;
}

.deployment-img{
    width: 60%;
    position: relative;
    top: 0;
    z-index: 1;
}

.deployment-img img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}



.features-wrapper{
    padding-top: 20%;
    height: auto;
}

.features-wrapper .containerFull{
    position: relative;
    display: flex;
}

.features-text{
    width: 45%;
}

.features-text h2{
    font-size: 2.5rem;
    font-weight: 400;
}
.features-text span{
    font-weight: 100;
    font-style: italic;
}

.features-text ul{
    width: 70%;
    padding-left: 1rem;
    padding-bottom: 10%;
}
.features-text li{
    margin-top: 2.5rem;
    font-weight: 100;
    list-style: disc;
}

li p{
    line-height: 1.7rem;
    padding-left: .5rem;
}

.features-img{
    width: 60%;
    height: 80%;
    position: absolute;
    right: -14%;
    bottom: 0%;
}

.features-img img{
    height: 100%;
    object-fit: cover;
    object-position: center;
}



.impact-wrapper{
    padding: 10% 0;
    height: auto;
    position: relative;
    background-color: var(--light-gray);
}

.impact-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../../images/all-nation/impact-bg.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

.impact-wrapper .containerFull{
    position: relative;
    display: flex;
}

.impact-text{
    width: 90%;
    text-align: center;
    margin: auto;
}

.impact-text h2{
    font-size: 2.5rem;
    font-weight: 400;
}
.impact-text span{
    font-weight: 100;
    font-style: italic;
}

.impact-text p{
    margin-top: 5rem;
    line-height: 1.7rem;
    font-weight: 100;
}



@media (max-width: 1250px){

    .challenge-text{
        padding-top: 8%;
        width: 35%;
    }

    .phone{
        top: 35%;
        right: 15%;
        height: 30rem;
    }

    .platform-text{
        width: 100%;
    }

    .bg-code{
        height: 25rem;
    }

    .features-wrapper {
        padding-top: 5%;
    }

    .deployment-img{
        height: 100%;
        width: auto;
        left: -7%;
    }
    .deployment-wrapper hr{
        margin-top: 8%;
    }

    .deployment-text {
        margin-top: 15%;
        padding-top: 0;
        margin-left: -5%;
    }

}

@media (max-width: 1000px){

    .nation-header-wrapper{
        padding: 10% 0;
    }

    .nation-header .g1 {
        height: 100%;
        width: auto;
    }
    .nation-header-title h1 {
        margin-top: 3.5rem;
        font-size: 4rem;
    }
    .nation-header-title h2 {
        font-size: 1.5rem;
        margin-top: 2rem;
    }

    .nation-header-title p{
        text-align: center;
        margin-top: 2.5rem;
    }

    .challenge-header{
        flex-direction: column;
    }

    .challenge-text{
        width: 100%;
        padding: 15% 0;
    }

    .challenge-img{
        width: 100%;
        height: 25rem;
        position: relative;
        right: 0;
    }
    .phone{
        top: 57%;
        right: 30%;
        height: 25rem;
    }

    .platform-wrapper{
        padding-top: 15%;
        height: 30rem;
    }
    .bg-code{
        height: 15rem;
    }

    .deployment-header{
        flex-direction: column-reverse;
    }

    .deployment-wrapper hr {
        display: none;
    }

    .deployment-img{
        width: 100%;
        left: 0;
        height: 25rem;
        right: 0;
    }
    .deployment-img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .deployment-text{
        width: 100%;
        margin-top: 0;
        padding: 8% 0;
        margin-left: 0;
    }

    .features-wrapper .containerFull {
        flex-direction: column;
    }
    .features-text{
        width: 100%;
        margin-top: 5rem;
        text-align: center;
    }
    .features-text ul{
        width: 100%;
        padding-left: 0;
    }
    
    .features-text li{
        list-style: none;
    }
    .features-img{
        width: 100%;
        height: 25rem;
        position: relative;
        right: 0;
    }
    .features-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .impact-text{
        width: 100%;
        margin-top: 5rem;
    }
    .impact-text p{
        margin-top: 2rem;
    }

    
    
}

@media (max-width: 700px){

    .features-img{
        display: none;
    }

    .impact-text h2{
        font-size: 1.7rem;
    }


}