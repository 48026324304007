
h3{
    font-size: 2.5rem;
    font-weight: 600;
}

.bridgewater-header{
    background: var(--bridge-blue);
    position: relative;
    color: var(--white-color);
    height: auto;
    width: 100%;
}


.bridgewater-header .containerFull{
    height: 100%;
    position: relative;
}

.bridgewater-header-text{
    padding: 8% 0;
    display: flex;
    justify-content: space-between;
}

.bridgewater-title{
    width: 55%;

}

.bridgewater-title h1{
    font-size: 5.5rem;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 0;
    color: var(--orange-brown);
}

.bridgewater-title h2{
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: -0.2rem;
    margin-bottom: 0;
    color: var(--brown-yellow);
}

.bridgewater-title-p{
    width: 45%;
    font-weight: 400;
    margin-top: 2rem;
    line-height: 2;
}

.bridgewater-landing{
    width: 100%;
    position: relative;
}

.bridgewater-landing img{
    position: relative;
    right: -3%;
}

.bridgewater-challenge{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.challenge-site{
    width: 40%;
}
.challenge-site img{
    width: 100%;
}

.challenge-points{
    width: 50%;
    height: 100%;
    text-align: right;
}

.challenge-points div:first-child{
    margin-bottom: 8rem;
}

.challenge-points h3{
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2.5rem;
    font-style: italic;
}
.challenge-points span{
    font-size: 1.7rem;
    font-weight: 700;
    font-style: normal;
    color: var(--brown-yellow);
}

.challenge-points p{
    font-weight: 300;
    line-height: 2;
}
.bridgewater-features{
    margin: 7rem 0;
}

.features-container{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bridgewater-features h3{
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;
}
.bridgewater-list{
    width: 40%;
    font-weight: 300;
}
.bridgewater-list li{
    margin-bottom: 3rem;
    list-style: disc;
    list-style-position: inside;
    color: var(--brown-yellow);
    font-weight: 500;
    font-style: italic;
}

.bridgewater-list li span{
    color: var(--white-color);
    font-weight: 300;
    line-height: 2;
    font-style: normal;
    margin-left: 1rem;
}

.bridge-features-img{
    width: 45%;
}

.bridge-features-img img{
    width: 100%;
}

.bridgewater-experience{
    width: 75%;
    height: 40rem;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 3;
}
.bridgewater-experience img{
    width: 80%;
    position: relative;
    margin: auto;
    box-shadow: -8px 8px 19px 0px rgb(0, 0, 0, .37);
}

/* DEVELOPMENT */

.bridgewater-development{
    width: 100%;
    height: 40rem;
    position: relative;
    background-image: url(../../images/bridgewater/development-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: var(--white-color);
}

.development-container{
    width: 75%;
    margin: auto;
    background-color: var(--chocolate-brown);
    padding: 5% 0;
    text-align: center;
    position: relative;
    z-index: 1;
}
.development-container h3{
    width: 90%;
    margin: auto;
    margin-top: 22%;
    margin-bottom: 1rem;
}

.development-container p{
    width: 80%;
    margin: 2rem auto 0;
    line-height: 2;
    font-weight: 100;
    font-style: italic;
}

/* SOLUTION */

.bridgewater-solution{
    width: 100%;
    position: relative;
    background-color: var(--chocolate-brown);
    color: var(--white-color);
    padding: 5rem 0 0;
    overflow: hidden;
}

.bridge-solution-container{
    display: flex;
    justify-content: space-between;
}

.bridge-solution-img{
    width: 40%;
    margin-bottom: -1.6rem;
    display: flex;
    justify-content: space-between;
}
.bridge-solution-img div{
    width: 50%;
    position: relative;
}
.bridge-solution-img img{
    width:100%;
    position: relative;
    margin-bottom: -1rem;
}

.bridge-solution-text{
    width: 50%;
    text-align: right;
}

.bridge-solution-text p{
    margin-top: 2rem;
    font-weight: 300;
    line-height: 2;
    margin-bottom: 3rem;
}


@media (max-width: 1250px ) {

    .bridgewater-landing img{
        width: 100%;
        right: 0;
    }
    
    .bridgewater-header-text{
        flex-direction: column;
        text-align: center;
    }
    .bridgewater-title{
        width: 100%;
    }

    .bridgewater-title-p{
        width: 100%;
    } 

    .challenge-points{
        margin-top: 8%;
    }
    .bridgewater-features{
        margin-bottom: 2rem;
    }

    .development-container h3{
        margin-top: 10%;
    }

    .bridge-solution-img{
        width: 60%;
        position: relative;
        left: -5%;
    }
}


@media (max-width: 1000px ) {
    .bridgewater-title h1{
        font-size: 4.5rem;
    }
    .bridgewater-title h2{
        font-size: 2rem;
    }
    .bridgewater-challenge{
        flex-direction: column-reverse;
    }

    .challenge-points{
        width: 100%;
        text-align: left;
    }
    .challenge-site{
        width: 100%;
        margin-top: 10%;
    }
    .features-container{
        flex-direction: column;
    }
    .bridgewater-list{
        width: 100%;
        margin: 2rem 0 0;
    }
    .bridge-features-img{
        display: none;
    }
    .bridgewater-experience{
        width: 100%;
        padding-bottom: 5rem;
    }
    .bridgewater-experience img{
        height: 100%;
    }
    .bridgewater-development{
        height: auto;
    }

    .bridgewater-development .containerFull{
        padding: 6rem 0;
    }
    .development-container{
        margin: 0;
        left: -15%;
        padding: 3rem 0 3rem 6rem ;
        text-align: left;
    }
    .development-container h3{
        margin: 0;
    }
    .development-container p{
        margin: 0;
        margin-top: 2rem;
    }

    .bridge-solution-container{
        flex-direction: column-reverse;
    }
    .bridge-solution-img{
        width: 100%;
        position: relative;
        left: 0;
    }
    .bridge-solution-text{
        width: 100%;
    }
}

@media (max-width: 700px ) {
    .bridgewater-title h1{
        font-size: 3rem;
    }
    .bridgewater-title h2{
        font-size: 1.5rem;
    }

    .challenge-points div:first-child {
        margin-bottom: 3rem;
    }
    .development-container{
        width: 100%;
    }
}