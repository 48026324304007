.seagrams-header-wrapper{
    width: 100%;
    background-image: url(../../images/seagrams/seagrams-wave.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: var(--white-color);
    padding: 5rem 0 8rem;
}

.seagrams-header-wrapper .containerFull{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.seagrams-header__title{
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    width: 50%;
}
.seagrams-header-wrapper p{
    font-weight: 300;
    font-style: italic;
    width: 45%;
    margin-bottom: 2rem;
    line-height: 2;
}


/* CHALLENGE */
.seagrams-challenge-wrapper{
    margin-bottom: 22rem;
}

.seagrams-challenge__container{
    width: 95%;
    margin: 7rem auto;
    max-width: 1400px;
    background: var(--gradient-sea-blue);
    color: var(--white-color);
    padding-top: 5rem;
}
.seagrams-challenge__container .containerFull{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seagrams-challenge__content{
    width: 45%;
}
.seagrams-challenge__content h2{
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
.seagrams-challenge__content span{
    opacity: .6;
}
.seagrams-challenge__content p{
    font-weight: 300;
    font-style: italic;
    margin-bottom: 2rem;
    line-height: 2;
}
.seagrams-challenge__img{
    width: 50%;
    height: 33rem;
}
.seagrams-challenge__img img{
    width: 100%;
    height: auto;
}

/* SOLUTION */
.seagrams-solution-wrapper{
    width: 100%;
    background-color: var(--sea-blue);
    color: var(--white-color);
    padding: 3em 0 14rem;
    margin-bottom: 3rem;
    overflow: hidden;
    position: relative;
}
.solution-wave{
    width: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

}
.seagrams-solution-wrapper .containerFull{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.seagrams-solution__text{
    width: 50%;
}
.seagrams-solution__text h2{
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
.seagrams-solution__text span{
    opacity: .6;
}
.seagrams-solution__text p{
    font-weight: 300;
    font-style: italic;
    margin-bottom: 2rem;
    line-height: 2;
}
.seagrams-solution__img{
    width: 50%;
    margin-bottom: -10%;
    display: flex;
    justify-content: center;
}
.seagrams-solution__img img{
    margin: auto;
}

/* FEATURES */
.seagrams-features-wrapper h2{
    width: 90%;
    margin: auto;
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 5rem;
    text-align: center;
    color: var(--sea-blue);
}
.feature-group{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
}
.seagrams-feature__text{
    width: 40%;
}
.seagrams-feature__text li{
    font-weight: 700;
    font-style: italic;
    margin-bottom: 2rem;
    line-height: 2;
    color: var(--sea-blue);
    list-style-type: disc;
}
.seagrams-feature__text li span{
    font-weight: 400;
    color: var(--black-color);
    margin-left: .5rem;
}
.seagrams-feature__img{
    width: 55%;
}
.seagrams-feature__img img{
    width: 100%;
    height: auto;
}


/* DEVELOPMENT */
.seagrams-development-wrapper{
    width: 100%;
    color: var(--white-color);
    position: relative;
    overflow: hidden;
}
.dev-waves{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.seagrams-development-wrapper .containerFull{
    position: relative;
    z-index: 3;
    overflow: hidden;
}
.seagrams-development-process{
    width: 60%;
    margin: auto;
    text-align: center;
    padding-top: 15%;
    margin-bottom: 5rem;
}
.seagrams-development-process h2{
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 5rem;
    color: var(--white-color);
    opacity: .6;
}
.seagrams-development-process p{
    font-weight: 300;
    font-style: italic;
    margin-bottom: 2rem;
    line-height: 2;
    color: var(--white-color);
}


/* OUTCOME */
.seagrams-outcome-wrapper{
    width: 100%;
    background-color: var(--sea-blue);
    color: var(--white-color);
    padding: 3em 0 5rem;
    margin-bottom: 4rem;
}
.seagrams-outcome{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seagrams-outcome__text{
    width: 40%;
    text-align: center;
}
.seagrams-outcome__text h2{
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    opacity: .6;
}
.seagrams-outcome__text p{
    font-weight: 300;
    font-style: italic;
    margin-bottom: 2rem;
    line-height: 2;
}
.seagrams-outcome__img{
    width: 50%;
    height: 33rem;
}
.seagrams-outcome__img img{
    width: 100%;
    height: auto;
}


@media (max-width: 1000px ) {
    .seagrams-header-wrapper .containerFull{
        flex-direction: column;
        align-items: center;
    }
    .seagrams-header__title{
        width: 90%;
        text-align: center;
    }
    .seagrams-header-wrapper p{
        width: 90%;
        text-align: center;
    }

    .seagrams-challenge__container .containerFull {
        flex-direction: column;
        align-items: center;
    }
    .seagrams-challenge__content{
        width: 100%;
        text-align: center;
    }
    .seagrams-challenge__img{
        width: 100%;
        margin-top: 3rem;
    }
    .seagrams-solution__text{
        width: 70%;
    }
    .seagrams-solution-wrapper{
        padding-bottom: 5rem;
    }
    .seagrams-solution__img{
        width: 25%;
    }

    .seagrams-features-wrapper h2{
        width: 100%;
        margin-bottom: 2rem;
    }
    .feature-group{
        flex-direction: column;
        align-items: center;
    }
    .seagrams-feature__text{
        width: 100%;
    }
    .seagrams-feature__img{
        width: 100%;
        margin: 2rem 0;
    }
    .second-list{
        margin-top: -12%;
    }

    .seagrams-development-process{
        width: 100%;
        padding-top: 10rem;
    }
    .seagrams-development-process h2{
        margin-bottom: 2rem;
    }

    .seagrams-outcome{
        flex-direction: column;
        align-items: center;
    }
    .seagrams-outcome__text{
        width: 100%;
        margin-bottom: 2rem;
    }
    .seagrams-outcome__img{
        width: 100%;
        height: auto;
        margin-bottom: -1rem;
    }
}