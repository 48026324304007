.ai-header {
    position: relative;
    padding: 3rem 0 10rem 0;
    background-color: var(--midnight-blue);
    overflow: hidden;
}

.ai-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3.5rem;
    position: relative;
    z-index: 2;
}

.ai-header-icon {
    width: 5rem;
    height: 5rem;
    background-color: var(--navy-blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-shadow: 10px 10px 40px rgb(0 0 0 / 20%);
}
.ai-header-icon svg {
    width: 2.4rem;
}

.ai-header-box{
    width: 80%;
    max-width: 52.5rem;
    color: var(--white-color);
    text-align: center;
}

.ai-header-copy {
    margin-top: 2rem;
    text-align: center;
}

.ai-header-copy p{
    line-height: 2;
}

.robohand-header{
    width: 90%;
    max-width: 62rem;
    margin-top: 3rem;
}
.robohand-header img{
    width: 100%;
}

.blur-circle.circle-ai{
    width: 31.25rem;
    height: 31.25rem;
}

.blur-circle.circle-ai.circle-pink.circle-ai-pink-1{
    top: -22%;
    transform: translateX(-200%);
}
.blur-circle.circle-ai.circle-violet.circle-ai-violet-1{
    top: -4%;
    transform: translateX(-140%);
}

.blur-circle.circle-ai.circle-pink.circle-ai-pink-2 {
    transform: translateX(-131%) scale(.65);
    bottom: 28%;
    opacity: .7;
}

.blur-circle.circle-ai.circle-violet.circle-ai-violet-2{
    top: -4%;
    transform: translateX(120%);
}
.blur-circle.circle-ai.circle-violet.circle-ai-violet-3{
    bottom: 22%;
    transform: translateX(0%) scale(.85);
}

.ai-custom-solutions{
    background-color: var(--midnight-blue);
    position: relative;
    padding: 3rem 0;
    min-height: 50vh;
}

.ai-custom-wrapper{
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}


.ai-custom-wrapper h3{
    text-align: center;
    color: var(--white-color);
}

/******** CUSTOM SOLUTIONS ********/

.custom-solution-grid{
    width: 100%;
    color: var(--white-color);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 18rem;
    margin-top: 4rem;
    grid-gap: 4.5rem 2.8rem;
}

.custom-solution-image img{
    width: 100%;
    display: block;
}

.custom-solution-main h4 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.custom-solution-main p {
    font-size: 1.125rem;
    line-height: 2;
}

.custom-solution-main p span{
    font-weight: 600;
}

.custom-solution-platforms{
    margin: 1.5rem auto;
}
.custom-solution-platforms ul{
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    margin-top: 1.25rem;
    flex-wrap: wrap;
}

.custom-solution-platforms ul li{
    background-color: rgb(200 214 229 / 20%);
    padding: 0.8rem;
    height: 3.5rem;
    box-sizing: border-box;
}

.custom-solution-platforms ul li img{
    display: block;
    height: 100%;
}

.custom-solution-before p,.custom-solution-after p{
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 2.5;
}

.custom-solution-before {
    background-color: rgb(34 47 62 / 40%);
    border-radius: 0.625rem;
    padding: 1.6rem 2.65rem 3rem 2.65rem;
}

.custom-solution-after {
    border-radius: 0.625rem;
    border: 2px solid transparent;
    background: 
    linear-gradient(to right, var(--midnight-blue), var(--midnight-blue) ), 
    linear-gradient(45deg, var(--orchid-pink) , var(--sky-blue), var(--light-purple), var(--vivid-purple)); 
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  padding: 1.6rem 2.65rem 3rem 2.65rem;


}

.custom-solution-before h6, .custom-solution-after h6{
    margin-bottom: 1rem;
}

/******** INDUSTRIES ********/

.aiIndustryWrapper{
    position: relative;
    overflow: hidden;
    min-height: 56rem;
    padding: 5rem 0 5rem 0;
    background-color: var(--navy-blue);
}

.aiIndustryWrapper h3{
    color: var(--white-color);
}




.ai-industry-list {
    margin-top: 2rem;
    margin-bottom: 5.5rem;
}

.ai-solutions {
    position: relative;
    overflow: hidden;
    padding: 3rem 0 3rem 0;
}
.ai-solutions-wrapper {
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
    z-index: 2;
}
.ai-solutions-item{
    display: flex;
    column-gap: 2.375rem;
}
.ai-solutions-item-ico{
    width: 5.6rem;
    height: 5.6rem;
    display: flex;
    justify-content:flex-end;
    align-items: flex-start;
}

.ai-solutions-item-ico img{
    display: block;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.ai-solutions-item h6 {
    font-size: 1.125rem;
    margin-bottom: .7rem;
    color: var(--white-color);
}
.ai-solutions-item p {
    font-size: 0.75rem;
    color: var(--light-gray);
    
}

.ai-solutions-list-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5.5rem;
}

@media (max-width: 900px) {

    .ai-custom-wrapper {
        width: 80%;
    }
    .custom-solution-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2.5rem 0rem;
        margin-bottom: 6rem;
    }

    .ai-solutions-wrapper {
        padding: 0;
    }

}

@media (max-width: 600px) {
    .custom-solution-main p{
        font-size: 1rem;
    }
    .custom-solution-before, .custom-solution-after {
        padding: 1.6rem 1.65rem 2rem 1.65rem;
    }
    .custom-solution-before p, .custom-solution-after p{
        line-height: 2;
    }
    .custom-solution-platforms ul li {
        height: 2.75rem;
        padding: 0.5rem;
    }
    .ai-industry-list {
        margin-bottom: 2rem;
    }
    .ai-solutions-list-wrapper {
        row-gap: 3rem;
    }
    .ai-solutions-item {
        flex-direction: column;
        row-gap: 0.75rem;
    }
    .ai-solutions-item-ico {
        justify-content: flex-start;
        width: 2.75rem;
        height: 2.75rem;
    }
}

