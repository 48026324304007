.judigo-header{
    height: 40rem;
    background: var(--black-color);
    position: relative;
    color: var(--white-color);
}

.judigo-header .containerFull{
    height: 100%;
    position: relative;
}

.judigo-header-wrapper{
    width: 100%;
    height: 100%;
    padding: 8% 0;
    display: flex;
    flex-direction: row;
    z-index: 2;
    position: relative;
}

.judigo-header-title{
    width: 100%;
}

.judigo-header-title img{
    width: 40rem;
}

.judigo-header-title h2{
    font-size: 3rem;
    font-weight: 100;
    font-style: italic;
    margin-top: -2rem;
}

.judigo-header-title p{
    width: 60%;
    font-weight: 100;
    margin-top: 6rem;
    line-height: 2rem;
}

.img-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.img-wrapper img{
    width: 100%;
}

.judigo-header .g1{
    position: absolute;
    top: -20%;
    left: -30%;
}

.judigo-header .g2{
    position: absolute;
    width: 50%;
    top: 15%;
    right: -10%;
}


/* SOLUTION */

.judigo-solution{
    background: var(--green-aqua);
    position: relative;
    color: var(--white-color);
}

.judigo-solution .containerFull{
    position: relative;
    height: 100%;
}

.judigo-solution-wrapper{
    width: 100%;
    height: 100%;
    padding: 10% 0;
    position: relative;
}

.judigo-solution-title{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.judigo-solution-title h2{
    font-size: 3rem;
    font-weight: 400;
}

.judigo-solution-title span{
    font-weight: 100;
    font-style: italic;
}

hr{
    width: 80%;
    border: none;
    height: .8px;
    background: var(--white-color);
    margin: 0 0 5%;
}

.judigo-solution-text p{
    color: var(--black-color);
    line-height: 2.5rem;
}


/* FEATURES CARDS */

.bgr-lines{
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(../../images/judigo-legal/lines.webp);
    background-repeat: no-repeat;
    z-index: 1;
}

.features-wrapper-judigo{
    position: relative;
}

.judigo-features .containerFull{
    height: 100%;
    position: relative;
}

.judigo-features{
    padding: 5% 0;
    position: relative;
    z-index: 2;
}

.judigo-features-cards{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.feature-card{
    width: 48%;
    height: 25rem;
    margin: 1rem 0;
    background: var(--white-color);
}

.feature-card h4{
    font-size: 1.5rem;
    font-weight: 400;
    padding: 4rem 2rem 1rem;
}
.feature-card p{
    font-weight: 100;
    line-height: 1.8rem;
    padding: 0 2rem;
}
.feature-card div{
    width: 96%;
    height: 100%;
    float: right;
}

.black{
    background-color: var(--green-aqua);
}
.black div{
    background-color: var(--blue-black);
    color: var(--white-color);
}

.feature-card img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.green{
    background-color: var(--blue-black);
}
.green div{
    background-color: var(--green-aqua);
}




/* TECH */

.judigo-tech{
    background: var(--beige-yellow);
    position: relative;
    color: var(--black-color);
}

.judigo-tech .containerFull{
    position: relative;
    height: 100%;
}

.judigo-tech-wrapper{
    width: 100%;
    height: 100%;
    padding: 10% 0;
    position: relative;
}

.judigo-tech-title{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.judigo-tech-title h2{
    font-weight: 100;
    font-style: italic;
    flex-shrink: 0;
    margin-left: 1rem;
}

.judigo-tech-title span{
    font-size: 3rem;
    font-weight: 400;
    font-style: normal;
}

.judigo-tech hr{
    background: var(--black-color);
    margin-bottom: 0;
}

.judigo-tech-img{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5%;
}

.judigo-tech-img div{
    width: 12rem;
    height: 5rem;
    margin: .5rem 0;
}

.judigo-tech-img div img{
    height: 100%;
    display: flex;
    margin: 0 auto;
}



/* DIGITAL SOLUTION */

.judigo-digital{
    background: var(--white-color);
    position: relative;
    color: var(--black-color);
}

.judigo-digital .containerFull{
    position: relative;
    height: 100%;
}

.judigo-digital-wrapper{
    width: 100%;
    height: 100%;
    padding: 8% 0;
    position: relative;
}

.judigo-digital-content{
    width: 100%;
    text-align: center;
}

.judigo-digital-content h2{
    font-weight: 100;
    font-style: italic;
}

.judigo-digital-content span{
    font-size: 3rem;
    font-weight: 400;
    font-style: normal;
}

.judigo-digital-content p{
    line-height: 2.5rem;
    margin: 5% 0;
}



@media (max-width: 1000px ) {

    .judigo-header-title img {
        width: 95%;
        margin-bottom: 1rem;
    }
    .judigo-header-title h2 {
        font-size: 2.5rem;
    }
    
    .judigo-header-title p {
        width: 80%;
        font-weight: 100;
        margin-top: 2rem;
    }

    .judigo-img{
        width: 15rem;
    }

    .judigo-solution-title hr{
        display: none;
    }

    .feature-card{
        width: 100%;
    }

    .judigo-tech hr{
        display: none;
    }

    .judigo-tech-title h2{
        flex-shrink: 1;
        text-align: center;
        margin-left: 0;
        margin-bottom: 2rem;
    }

    .judigo-tech-img div{
        width: 10rem;
        height: 4rem;
        margin-bottom: 3rem;
    }

    
}


@media (max-width: 700px){


    .judigo-header-title h2 {
        font-size: 2rem;
    }

    .judigo-header-title p {
        width: 100%;
    }

    .judigo-img{
        display: none;
    }

    .feature-card div{
        overflow-y: hidden;
        overflow-y: scroll;
    }
    

    .feature-card h4{
        font-size: 1.2rem;
        padding-top: 2rem
    }

    .feature-card p{
        font-size: .8rem;
    }


}


