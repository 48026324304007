/******** PAYMENT HEADER ********/

.payment-header{
    height: 48rem;
    background: var(--gradient-green-darkviolet);
    position: relative;
}

.payment-header .containerFull{
    height: 100%;
}

.payment-header-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    z-index: 2;
    position: relative;
}

.payment-header-copy, .payment-header-image{
    flex: 1 1;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.payment-header-copy{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-color);
    text-align: center;
    row-gap: 2rem;
    justify-content: center;
}
.payment-header-copy p{
    line-height: 2;
}

.payment-header-icon {
    width: 5rem;
    height: 5rem;
    background-color: var(--green-mint);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-shadow: 15px 15px 40px rgb(0 0 0 / 70%);
    margin-top: -10rem;
}

.payment-header-icon svg{
    width: 2.4rem;
}

.payment-cc-base {
    position: relative;
    width: 100%;
    max-width: 614px;
    margin-bottom: -6rem;
}
.payment-cc-base img{
    width: 100%;
    display: block;
}
.payment-cc-effect {
    position: absolute;
    top: -28%;
    left: 6%;
    width: 88%;
}

.payment-cc-layer-effect {
    position: absolute;
    background-color: rgb(34 47 62 / 25%);
    width: 53%;
    height: 64%;
    transform: rotate(-120deg) skewY(-30.5deg);
    top: -20.48%;
    left: 23.75%;
    border-radius: 4%;
    backdrop-filter: blur(10px);
}

.payments-figures {
    position: absolute;
}

.payment-donut {
    width: 41.9%;
    top: -55%;
    left: 21%;
    z-index: -1;
}
.payment-cone {
    width: 42.55%;
    top: -25%;
    right: -10%;
    z-index: 1;
}
.payment-cube {
    width: 29.65%;
    top: 75%;
    left: 7%;
}

/******** TECHNOLOGY STACK ********/

.technology-stack{
    position: relative;
    background-color: var(--royal-purple);
    overflow: hidden;
    padding: 5rem 0;
}

.technology-stack-wrapper{
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 58.75rem;
    text-align: center;
    color: var(--white-color);
}

.technology-stack-wrapper h3 {
    margin-bottom: 2.75rem;
}

.technology-stack-wrapper p {
    line-height: 2;
}

.technology-logo-list {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    justify-content: center;
}

.tech-logo-item{
    width: 6.25rem;
    height: 6.25rem;
    background-color: rgb(0 0 0 / 30%);
    border-radius: 5px;
    padding: 1.225%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tech-logo-item img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}

/******** EXPERTISE & SERVICES ********/

.cross-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../../images/x-bgr.png);
    opacity: 50%;
    z-index: 1;
}

.expertise-services {
    position: relative;
    background-color: var(--white-color);
    overflow: hidden;
    padding: 5rem 0;
}

.expertise-services-wrapper {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    color: var(--navy-blue);
}

.expertise-services-grid {
    margin-top: 3.75rem;
    display: grid;
    grid-template-columns: 43.25% 1fr;
    grid-template-rows: repeat(4, 7rem);
    grid-column-gap: 0.625rem;
    grid-row-gap: 0.625rem;
}

.expertise-services-item-1 {
    grid-area: 1 / 1 / 2 / 2;
}
.expertise-services-item-2 {
    grid-area: 2 / 1 / 3 / 2;
}
.expertise-services-item-3 {
    grid-area: 3 / 1 / 4 / 2;
}
.expertise-services-item-4 {
    grid-area: 4 / 1 / 5 / 2;
}
.expertise-services-content {
    grid-area: 1 / 2 / 5 / 3;
}

.expertise-services-item {
    width: 100%;
    height: 7rem;
    position: relative;
    cursor: pointer;
}
.expertise-services-text {
    position: relative;
    box-sizing: border-box;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 2.68rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--navy-blue);
    transition: color 250ms ease-in;
    column-gap: 1rem;
}
.expertise-services-item svg {
    width: 3.5rem;
}

.expertise-services-item svg .styleBase{
    fill: var(--midnight-blue) !important;
    transition: fill 250ms ease-in;
}
.expertise-services-item:hover svg .styleBase, .expertise-services-item.active svg .styleBase{
    fill: var(--white-color) !important;
}

.expertise-services-label {
    width: 100%;
    height: 100%;
    background-color: #ECF3FA;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: rotate(0);
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    transition: all 250ms ease-in;
}
.expertise-services-item:hover .expertise-services-label, .expertise-services-item.active .expertise-services-label{
    background-color: var(--midnight-blue);
    transform: rotate(-2.08deg);
    box-shadow: 20px 20px 20px rgb(0 0 0 / 20%);
}
.expertise-services-item:hover .expertise-services-text, .expertise-services-item.active .expertise-services-text {
    color: var(--white-color);
}

.expertise-services-content{
    display: flex;
    opacity: 0;
    transition: opacity 150ms ease-in;
}
.expertise-services-content.active{
    opacity: 1;
}

.expertise-services-content-text{
    display: flex;
    align-items: center;
    background-color: #ECF3FA;
    padding: 7% 10.14%;
    text-align: center;
}
.expertise-services-content p{
    line-height: 2.2;
}

/******** MARKETS WE SERVE ********/

.payment-markets {
    position: relative;
    background-color: var(--white-color);
    overflow: hidden;
    padding: 2rem 0 0 0;
    box-sizing: border-box;
}

.payment-markets-wrapper {
    text-align: center;
    padding: 4rem 0rem;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    color: var(--navy-blue);
}

.payment-industry-grid{
    display: grid;
    width: 100%;
    max-width: 56.25rem;
    margin: 1rem auto;
    grid-template-columns: repeat( 3, 1fr );
}

.payment-industry-item {
    position: relative;
    padding-top: 100%;
}

.payment-industry-card {
    position: absolute;
    width: 13.75rem;
    height: 13.75rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    transform: rotate(0deg) scale(1);
    transition: all 250ms ease-in;
}
.payment-industry-item p{
    position: absolute;
    bottom: 1rem ;
    right: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--white-color);
    line-height: 1;
    z-index: 4;
}
.payment-industry-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--vivid-purple);
    z-index: 2;
    mix-blend-mode: multiply;
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.payment-industry-card:hover, 
.payment-industry-item.active .payment-industry-card{
    transform: rotate(-3.65deg) scale(1.04);
    box-shadow: 20px 20px 20px rgb(0 0 0 / 20%);
}
.payment-industry-card:hover .payment-industry-overlay, 
.payment-industry-item.active .payment-industry-card .payment-industry-overlay{
    opacity: 0;
}

.payment-industry-item img{
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

/******** SOLUTIONS ********/

.payment-solutions {
    position: relative;
    background: var(--gradient-vertical-green-violet);
    overflow: hidden;
    padding: 5rem 0 8rem 0;
    
}
.payment-solutions h3{
    text-align: center;
    margin-bottom: 4rem;
}
.payment-solutions-wrapper {
    margin: 0 auto;
    max-width: 58.75rem;
    position: relative;
    z-index: 2;
    color: var(--white-color);
}

.payment-industry-big-name {
    font-size: 20rem;
    line-height: 1;
    color: rgb(0 0 0 / 15%);
    mix-blend-mode: overlay;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -.5rem;
    text-align: center;
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    margin: auto;
}

.payment-solutions-list-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
}

.payment-solutions-item h6{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.solutions-grid-capabilities-group {
    display: flex;
    gap: 0.75rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
}

.solutions-grid-capabilities-group .solutions-capability {
    background-color: rgb(255 255 255 / 30%);
    border-radius: 10rem;
    color: var(--white-color);
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    line-height: 1;
}

@media (max-width: 1200px) {
    .expertise-services-text {
        padding: 1rem 1rem;
        font-size: 1.25rem;
        column-gap: 0.75rem;
    }
    .expertise-services-item svg {
        width: 3rem;
    }
    .expertise-services-content p {
        line-height: 2;
    }
}

@media (max-width: 900px) {

    .payment-header-wrapper {
        flex-direction: column;
    }
    .payment-header-copy, 
    .payment-header-image {
        width: 100%;
    }
    .payment-industry-grid{
        margin-top: 3rem;
    }
    .payment-industry-card {
        width: 90%;
        height: 90%;
    }
    .payment-industry-item p {
        font-size: 1.25rem;
    }
    .expertise-services-grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, auto);
    }
    .expertise-services-item-1 { grid-area: 1 / 1 / 2 / 2; }
    .expertise-services-content-1 { grid-area: 2 / 1 / 3 / 2; }
    .expertise-services-item-2 { grid-area: 3 / 1 / 4 / 2; }
    .expertise-services-content-2 { grid-area: 4 / 1 / 5 / 2; }
    .expertise-services-item-3 { grid-area: 5 / 1 / 6 / 2; }
    .expertise-services-content-3 { grid-area: 6 / 1 / 7 / 2; }
    .expertise-services-item-4 { grid-area: 7 / 1 / 8 / 2; }
    .expertise-services-content-4 { grid-area: 8 / 1 / 9 / 2; }

    .expertise-services-item {
        height: 6rem;
    }

    .expertise-services-content{
        max-height: 0;
        opacity: 1;
        overflow: hidden;
        transition: max-height 150ms ease-in;
    }
    .expertise-services-content.active{
        opacity: 1;
        height: auto;
        max-height: 500px;
    }

    .expertise-services-text{
        padding: 1rem 2rem;
        font-size: 1.15rem;
    }
    .expertise-services-item svg {
        width: 2.25rem;
    }
    .expertise-services-item:hover .expertise-services-label, 
    .expertise-services-item.active .expertise-services-label {
        transform: rotate(-1.08deg);
        box-shadow: 10px 10px 15px rgb(0 0 0 / 20%);
    }
}



